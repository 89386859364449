import React from 'react';
import './styles/App.scss';
import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';
import Login from './auth/Login';
import Register from './auth/Register';
import Nav from './Nav';
import Home from './Home';
import Versus from './Versus';
// import Classroom from './Classroom';
import Analyze from './Analyze';
import Search from './Search';
import Quiz from './Quiz';
import Results from './Results';
import Linguistics from './Linguistics';
import Feedback from './Feedback';
import Account from './Account';
import PrivateRoute from './util/PrivateRoute';

// Anyone working on this in the future maybe look into not using lodash and do things more manually instead
// If you enter npm audit in the Terminal it calls lodash prototype pollution

class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoggedIn: false,
            hasLoginCheckRun: false,
            username: "",
            language: "tag_en",
            accountType: "student",
            linguisticInfo: {}
        }
        this.storeUserData = this.storeUserData.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.languageChange = this.languageChange.bind(this);
        this.getLinguisticInfo = this.getLinguisticInfo.bind(this);
    }

    async componentDidMount() {
        await fetch('/api/checkLoginStatus')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                if (data.status === true && this.state.isLoggedIn === false) {
                    this.setState({
                        isLoggedIn: data.status,
                        username: data.username,
                        language: data.language,
                        accountType: data.account_type
                    });
                } else if (data.status === false && this.state.isLoggedIn === true) {
                    this.setState({
                        isLoggedIn: data.status
                    });
                }
            })
            .catch(error => console.error("Fetch operation failed.", error));

        await this.getLinguisticInfo();

        this.setState({ hasLoginCheckRun: true });
    }

    async getLinguisticInfo() {
        await fetch('/api/getLinguisticInfo')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log(data)
                this.setState({ linguisticInfo: data.linguisticInfo });
            })
            .catch(error => console.error("Fetch operation failed.", error));
    }

    // Logout user so stop working with the user data
    async handleLogout() {
        await fetch('/api/logout')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log(data)
            })
            .catch(error => console.error("Fetch operation failed.", error));

        this.setState({
            username: "",
            isLoggedIn: false
        });
    }

    // When logged in start working with user's data
    storeUserData(data) {
        this.setState({
            username: data.username,
            language: data.lang,
            isLoggedIn: true
        });
    }

    //
    languageChange(event) {
        this.setState({ language: event.target.value });
    }

    render() {
        return (
            <BrowserRouter>
                <Nav
                    {...this.props}
                    username={this.state.username}
                    language={this.state.language}
                    isLoggedIn={this.state.isLoggedIn}
                    hasLoginCheckRun={this.state.hasLoginCheckRun}
                    handleLogout={this.handleLogout}
                    languageChange={this.languageChange}
                />
                <Route exact path="/">
                    <Redirect to="/home" />
                </Route>
                {this.state.hasLoginCheckRun === true &&
                    <Switch>
                        <Route
                            exact path={"/login"}
                            render={props => (
                                <Login
                                    {...props}
                                    storeUserData={this.storeUserData}
                                />
                            )}
                        />
                        <Route exact path="/register" component={Register} />
                        <PrivateRoute
                            linguisticInfo={this.state.linguisticInfo}
                            isLoggedIn={this.state.isLoggedIn}
                            hasLoginCheckRun={this.state.hasLoginCheckRun}
                            language={this.state.language}
                            username={this.state.username}
                            accountType={this.state.accountType}
                            path="/home"
                            exact component={Home}
                        />
                        <PrivateRoute
                            linguisticInfo={this.state.linguisticInfo}
                            isLoggedIn={this.state.isLoggedIn}
                            hasLoginCheckRun={this.state.hasLoginCheckRun}
                            language={this.state.language}
                            username={this.state.username}
                            path="/versus"
                            exact component={Versus}
                        />
                        {/* <PrivateRoute
                            linguisticInfo={this.state.linguisticInfo}
                            accountType={this.state.accountType}
                            isLoggedIn={this.state.isLoggedIn}
                            hasLoginCheckRun={this.state.hasLoginCheckRun}
                            language={this.state.language}
                            path="/classroom"
                            component={Classroom}
                        /> */}

                        <PrivateRoute
                            linguisticInfo={this.state.linguisticInfo}
                            isLoggedIn={this.state.isLoggedIn}
                            hasLoginCheckRun={this.state.hasLoginCheckRun}
                            language={this.state.language}
                            path="/quiz/:quiz"
                            component={Quiz}
                        />
                        <PrivateRoute
                            isLoggedIn={this.state.isLoggedIn}
                            linguisticInfo={this.state.linguisticInfo}
                            hasLoginCheckRun={this.state.hasLoginCheckRun}
                            language={this.state.language}
                            path="/results"
                            component={Results}
                        />
                        <PrivateRoute
                            linguisticInfo={this.state.linguisticInfo}
                            isLoggedIn={this.state.isLoggedIn}
                            hasLoginCheckRun={this.state.hasLoginCheckRun}
                            language={this.state.language}
                            path="/analyze"
                            exact component={Analyze}
                        />
                        <PrivateRoute
                            linguisticInfo={this.state.linguisticInfo}
                            isLoggedIn={this.state.isLoggedIn}
                            hasLoginCheckRun={this.state.hasLoginCheckRun}
                            language={this.state.language}
                            path="/search"
                            exact component={Search}
                        />
                        <PrivateRoute
                            isLoggedIn={this.state.isLoggedIn}
                            hasLoginCheckRun={this.state.hasLoginCheckRun}
                            language={this.state.language}
                            path="/linguistics"
                            exact component={Linguistics}
                        />
                        <PrivateRoute
                            isLoggedIn={this.state.isLoggedIn}
                            hasLoginCheckRun={this.state.hasLoginCheckRun}
                            language={this.state.language}
                            path="/feedback"
                            exact component={Feedback}
                        />
                        <PrivateRoute
                            isLoggedIn={this.state.isLoggedIn}
                            hasLoginCheckRun={this.state.hasLoginCheckRun}
                            language={this.state.language}
                            path="/account"
                            exact component={Account}
                        />
                    </Switch>
                }
            </BrowserRouter>
        );
    }
}

export default App;