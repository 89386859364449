import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import './styles/Classroom.scss';

const ClassroomGrid = (props) => {
    const [classes, setClasses] = useState([]);
    const [chosenClassrooms, setChosenClassrooms] = useState([]); // For adding a collection to classrooms
    const [openInput, setOpenInput] = useState(false);
    const [classNameInput, setClassNameInput] = useState("");

    useEffect(() => {
        getClasses();
    }, []);

    useEffect(() => {

    }, [classes]);

    function handleChange(event) {
        setClassNameInput(event.target.value);
    }

    //copy rename (1)
    // tags
    // teacher refreence
    // filters

    async function getClasses() {
        const response = await fetch('/api/getClasses');
        if (!response.ok) return;
        const data = await response.json();

        setChosenClassrooms(Array(data.classes.length).fill({ id: -1 }));
        setClasses(data.classes);

        console.log(data.classes)
    }

    // Join or create a new class
    async function newClassroom() {
        const newClassFunc = props.accountType === 'student' ? '/api/joinClass' : '/api/createClass';

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ class_code: classNameInput, name: classNameInput })
        };
        const response = await fetch(newClassFunc, requestOptions);
        if (!response.ok) return;

        if (props.accountType === 'student') {
            const data = await response.json();
            if (data.alertMessage.length > 0) {
                alert(data.alertMessage);
                return;
            }
        }

        setOpenInput(false);
        getClasses();
    }

    async function addCollectionToClasses() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                collection_id: props.collectionForClass.id,
                class_ids: chosenClassrooms.map(({ id }) => id).filter(i => i !== -1) // Array of chosen classroom ids
            })
        };
        const response = await fetch('/api/addCollectionToClasses', requestOptions);
        if (!response.ok) return;

        props.setCollectionForClass({ id: -1 })
        alert("Sucessfully added")
    }

    return (
        <div className={props.parent === "DeckSelection" ? "ClassroomGrid-overlay" : "c"}>
            <div className="classrooms-container">
                {(classes.length === 0 || chosenClassrooms.length === 0) ?
                    <div className="no-classes">
                        No classes
                    </div>
                    :
                    <>
                        {classes.map((classroom, c) =>
                            <div
                                key={c}
                                className={chosenClassrooms[c].id > -1 ? 'chosen-classroom' : 'classroom'}
                                onClick={() => {
                                    if (props.parent === 'DeckSelection') {
                                        let chosen = [...chosenClassrooms];
                                        chosen[c] = chosen[c].id === -1 ? classroom : { id: -1 };
                                        setChosenClassrooms(chosen);
                                        return;
                                    }
                                    props.setChosenClassroom(classroom);
                                    sessionStorage.setItem('classroom', JSON.stringify(classroom));
                                }}
                            >
                                <div className="name">
                                    {classroom.name}
                                </div>
                                <div className="code">
                                    Class code: <b>{classroom.class_code}</b>
                                </div>
                                {classroom.teacher}
                                {classroom.studentAmount}
                            </div>
                        )}
                    </>
                }
            </div>
            {props.parent === "DeckSelection" &&
                <>
                    {chosenClassrooms.map(({ id }) => id).filter(i => i !== -1).length > 0 ?
                        <>
                            <div className='addCollectionToClass'>
                                <h4 className='instruction'>
                                    Add {props.collectionForClass.name} to
                                    {chosenClassrooms.map((classroom, c) =>
                                        <span key={c}>
                                            {classroom.name !== undefined &&
                                                <> {classroom.name},</>
                                            }
                                        </span>
                                    )}
                                </h4>
                                <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    className="check"
                                    title="Add"
                                    onClick={() => addCollectionToClasses()}
                                />
                            </div>
                        </>
                        :
                        <h4 className='instruction'>
                            Select classes to add {props.collectionForClass.name} to
                        </h4>
                    }
                </>
            }
            <div className="create-class">
                {!openInput ?
                    <button onClick={() => setOpenInput(true)}>
                        {props.accountType === "teacher" ? <>Create class</> : <>Join class</>}
                    </button>
                    :
                    <>
                        <input
                            placeholder={props.accountType === "teacher" ? "Name" : "Class code"}
                            value={classNameInput}
                            onChange={handleChange}
                            onKeyPress={event => ((event.keyCode || event.which === 13) && classNameInput.length > 0) && newClassroom()}
                        >
                        </input>
                        <button
                            onClick={() => {
                                console.log(props.accountType)
                                if (classNameInput.length > 0) {
                                    newClassroom();
                                    return;
                                }
                                alert("Fill in the blank input.");
                            }}
                        >
                            {props.accountType === "teacher" ? <>Create class</> : <>Join class</>}
                        </button>
                    </>
                }
                {props.parent === "DeckSelection" &&
                    <button className="cancel" onClick={() => props.setCollectionForClass({ id: -1 })}>
                        Cancel
                    </button>
                }
            </div>
        </div>
    );
}

export default ClassroomGrid;