import React, { useState, useEffect } from 'react';
import Label from './Label';
import GeneratePronoun from './GeneratePronoun';
import '../styles/FlashcardForms.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltDown } from '@fortawesome/free-solid-svg-icons';

const FlashcardForms = (props) => {
    const [verbInput, setVerbInput] = useState("");
    const [inputMode, setInputMode] = useState("verbInput");

    // Item related
    const [badFont, setBadFont] = useState(false);
    const [flame, setFlame] = useState(false);
    const [burntVerb, setBurntVerb] = useState("");
    const [measureWord, setMeasureWord] = useState(false);

    // Makes input the choice
    useEffect(() => {
        setVerbInput(props.choice);
        setInputMode("hintInput");
    }, [props.choice]);

    // When Check button is pressed on Quiz component
    // (Enter or Next button on QuizM)
    useEffect(() => {
        if (props.answered) {
            isCorrect();
            if (props.game) { // disable certain item effects
                setBadFont(false);
                setFlame(false);
                setMeasureWord(false);
            }
        }
    }, [props.answered]);

    // Reset input for next flashcard
    useEffect(() => {
        resetInput();
    }, [props.questionNumber]);

    useEffect(() => { // Items
        console.log(props.item)
        if (props.block) {
            props.setBlock(false);
        } else if (props.item !== undefined) {
            switch (props.item.name) {
                case "erase":
                    props.applyItemAndUpdateStatus(props.item);
                    resetInput();
                    break;
                case "unscramble":
                    setVerbInput(props.verb.shuffle());
                    props.applyItemAndUpdateStatus(props.item);
                    break;
                case "ruler":
                    setMeasureWord(true)
                    props.applyItemAndUpdateStatus(props.item);
                    break;
                case "flame":
                    props.applyItemAndUpdateStatus(props.item);
                    let verbToBurn = props.infinitive;
                    for (let i = 0; i < 4; i++) {
                        let random = Math.floor(Math.random() * verbToBurn.length);
                        verbToBurn = verbToBurn.replaceAt(random, '#')
                    }
                    setBurntVerb(verbToBurn);
                    setFlame(true);
                    break;
                case "bad font":
                    props.applyItemAndUpdateStatus(props.item);
                    setBadFont(true);
                    break;

            }
        }
    }, [props.item])

    // unscramble
    String.prototype.shuffle = function () {
        var a = this.split(""),
            n = a.length;

        for (var i = n - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var tmp = a[i];
            a[i] = a[j];
            a[j] = tmp;
        }
        return a.join("");
    }

    // burn
    String.prototype.replaceAt = function (index, replacement) {
        if (index >= this.length) return this.valueOf();
        return this.substring(0, index) + replacement + this.substring(index + 1);
    }

    function resetInput() {
        setVerbInput("");
        setInputMode("verbInput")
    }

    // Changes the value of the verb input if the user didn't submit their answer
    function handleChange(event) {
        const re = /^[A-Za-zÀ-ÖØ-öø-ÿ]+$/;// Only accept letters and letters with accents
        if (event.target.value === "" || re.test(event.target.value)) {
            if (props.parent === "Results") {
                setVerbInput(event);
            }
            else if (props.parent === "Quiz") {
                !props.answered && setVerbInput(event.target.value);
                event.target.value.length > 0 ? props.setSkipOrSubmit("Submit") : props.setSkipOrSubmit("Skip");
            } else {
                setVerbInput(event.target.value);
            }
        }
    }

    async function isCorrect() {
        let res;
        // Check if answer is correct for type mode /**FIX how colors change */
        if ((props.flashcardMode === "conjugate") &&
            ((props.parent === "FlashcardGrid") || (inputMode === "verbInput") || (inputMode === "hintInput"))) {

            // If answer is correct
            if (verbInput === props.verb) {
                setInputMode("rightInput");
                if (props.parent === "Quiz") {
                    res = await props.processResult(true, verbInput);
                    if (props.game) props.transitionToNextQuestion(res); // should be fixed
                }
            }
            else {
                setInputMode("wrongInput");
                if (props.parent === "Quiz") {
                    res = await props.processResult(false, verbInput);
                    if (props.game) props.transitionToNextQuestion(res);
                }
            }
        }

        // Check if answer is correct for tense mode /**change where these are maybe keep the input stuff here only */
        if ((props.flashcardMode === "identify_tense") && (props.parent === "Quiz")) {
            if (props.choice === props.tags.tense) {
                res = await props.processResult(true, props.choice);
                console.log(res, props.history)
                if (props.game) props.transitionToNextQuestion(res);
            }
            else {
                res = await props.processResult(false, props.choice);
                console.log(res, props.history)
                if (props.game) props.transitionToNextQuestion(res);
            }
        }
    }

    var attributesSide = <figure className={props.flashcardMode === "flip" ? "front" : "back2"}>
        <div className='upper'>
            <Label
                linguisticInfo={props.linguisticInfo}
                tags={props.tags}
            />
        </div>
        <div className='delim'>of</div>
        <div className='lower'>
            {props.infinitive}
        </div>
    </figure>;

    var conjugatedSide = <figure className={props.flashcardMode === "flip" ? "back" : "front2"}>
        {props.verb}
    </figure>;

    if (props.flashcardMode === "raw") {
        return <>
            <div className='upper'>
                <Label
                    linguisticInfo={props.linguisticInfo}
                    tags={props.tags}
                />
            </div>
            <div className='delim'><hr /></div>
            <div className='lower'>
                {props.verb}
            </div>
        </>
    }
    else if (props.flashcardMode === "flip") {
        return <div className="card">
            {attributesSide}{conjugatedSide}
        </div>
    }
    else if (props.flashcardMode === "flip2") {
        return <div className="card">
            {conjugatedSide}{attributesSide}
        </div>
    }
    // Question Type 1 - Fill in the verb
    else if (props.flashcardMode === "conjugate") { // Move wrapper here
        return <>
            <div className={`form ${badFont && "badfont"}`}>
                <b>
                    <Label
                        linguisticInfo={props.linguisticInfo}
                        tags={{ tense: props.tags.tense, mood: props.tags.mood }} // Just show the tense and mood
                    />
                </b>
            </div>
            <div className={`infinitive ${badFont && "badfont"}`}>
                {flame ? <>{burntVerb}</> : <>{props.infinitive}</>}
            </div>
            <div className="blank">
                {(inputMode === "wrongInput" || (props.parent === "Results" && props.history?.correct === 0)) &&
                    <div className="showAnswer">{props.verb}</div>
                }
                <span className="pronoun">
                    <GeneratePronoun
                        person={props.tags.person}
                        number={props.tags.number}
                    />
                </span>
                {props.parent === "Results" ?
                    <span
                        className={props.history?.correct === 1 ? "rightInput" : "wrongInput"}

                    >
                        {props.history?.correct === 1 ?
                            <>{props.verb}</>
                            :
                            <>{props.history?.attempt.length > 0 ? <>{props.history?.attempt}</> : <span className='skipped'>skipped</span>}</>}
                    </span>
                    :
                    <>
                        <input
                            placeholder="verb"
                            type="text"
                            className={inputMode}
                            value={verbInput}
                            onChange={handleChange}
                            onKeyPress={event => {
                                if (event.keyCode || event.which === 13) {
                                    if (props.parent === "Quiz") {
                                        props.setAnswered(true);
                                        return;
                                    }
                                    isCorrect(); // For Home
                                }
                            }}
                            onPaste={(event) => {
                                event.preventDefault();
                                return false;
                            }}
                            onCopy={(event) => {
                                event.preventDefault();
                                return false;
                            }}
                        />
                        {measureWord &&
                            <>
                                <br />
                                <span className="measureWord">{props.verb.length - verbInput.length} letters left</span>
                            </>
                        }
                    </>

                }
            </div>
        </>
    } // Question Type 2 - What's the tense
    else if (props.flashcardMode === "identify_tense") {
        return <>
            <div className={`${badFont && "badfont"}`}>
                {flame ? <>{burntVerb}</> : <>{props.infinitive}</>}
            </div>
            <div>
                <FontAwesomeIcon icon={faLongArrowAltDown} className="down" />
                {props.parent === "Results" && props.history !== undefined && // skip if no history
                    <span>
                        <div className='rightInput'>
                            <Label
                                linguisticInfo={props.linguisticInfo}
                                tags={{ tense: props.tags.tense }}
                            />
                        </div>
                        {props.history?.correct === 0 &&
                            <div className='wrongInput'>
                                <Label
                                    linguisticInfo={props.linguisticInfo}
                                    tags={{ tense: props.history?.attempt }} // Just show the tense and mood
                                />
                            </div>
                        }
                    </span>
                }
            </div>
            <div className={`${badFont && "badfont"}`}>
                <span className="pronoun">
                    <GeneratePronoun
                        person={props.tags.person}
                        number={props.tags.number}
                    />
                </span>
                &nbsp;{props.verb/**check about formatting this nice */}
            </div>
        </>
    }
    return null;
}

export default FlashcardForms;        