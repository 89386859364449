import React from 'react'
import items from '../../data/items.json'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEraser, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import '../styles/Rules.scss'

export default function Rules(props) {
    return (
        <div className="DSoverlay">
            <div className="Rules">
                <div className='head'>
                    <h3 className='header subtitle'>How to win</h3>
                    <FontAwesomeIcon icon={faTimesCircle} onClick={() => props.setShowRules(false)}></FontAwesomeIcon>
                </div>
                <p className='how-to-play'>
                    Gain the most points by answering questions to win!
                    The faster you answer correctly, the more points are rewarded.
                    You will randomly obtain items along the way.
                    Use assist items to help yourself out
                    and use offense items to sabotage your opponent.
                    <br /><br />
                    Time trials will randomly appear, if you get the current question right within 5 seconds, you will get an item for sure.
                    If in dire need for an item you can sacrifice points for a higher chance to obtain one.
                </p>
                <div className='head'>
                    <h3 className='header subtitle'>Controls</h3>
                </div>
                <p className='how-to-play'>
                    To go to the next question, click the "Submit" or "Skip" button.
                    The [Enter] key will also work for this.
                    To use an item click on an item that you have in your inventory.
                    You can also use the [1] key to use the top item and the [2] key for the bottom item in your inventory.
                </p>
                <div className='head'>
                    <h3 className='header subtitle'>Item tier list</h3>
                    <div className='legend'>
                        <div className='box red'></div> Offense
                        <div className='box green'></div> Assist
                    </div>
                </div>
                {['A', 'B', 'C', 'D', 'M'].map((tier, t) =>
                    <div className='tier-row' key={t}>
                        <h2 className='header'>{tier} ~</h2>
                        <div className='items'>
                            {items[tier].map((item, i) =>
                                <div className='item' key={i}>
                                    <div className='icon' style={{ textShadow: item.type === "assist" ? "-4px -6px lightgreen" : "-4px 6px lightcoral" }}>
                                        {item.icon === "erase" ?
                                            <FontAwesomeIcon icon={faEraser} /> :
                                            <>{item.icon}</>
                                        }
                                    </div>
                                    <div className='name'>{item.name.toUpperCase()}</div>
                                    <div className='description'>{item.description}</div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
