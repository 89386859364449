import React, { useEffect } from 'react';
import './styles/Results.scss';
import FlashcardForms from './util/FlashcardForms';
// import './styles/FlashcardForms.scss';
import { Link } from 'react-router-dom';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Results = (props) => {

	let parentProps;

	if (props.location) parentProps = props.location;
	else parentProps = props;

	const { flashcards, quizType, points, correct, chosenDeck, class_id, history, linguisticInfo, parent } = parentProps;

	console.log(parentProps, 90909)

	useEffect(() => {
		if (parent === "Classroom") addRecord();
	}, []);

	async function addRecord() {
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				points: points,
				score: correct,
				deck_size: flashcards.length,
				time: 1,
				deck_id: chosenDeck.id,
				class_id: class_id,
				quiz_type: quizType,
				attempts: history
			}),
		}

		const response = await fetch("/api/addRecord", requestOptions)
		if (!response.ok) return
	}

	async function getRecord() {
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({ class_history_id: props.class_history_id })
		}

		const response = await fetch("/api/getRecord", requestOptions)
		if (!response.ok) return
		const data = await response.json();


	}

	console.log(history)

	return (
		<div className="Results">
			<h2 className='header'>Results</h2>
			<h3>{correct}/{flashcards.length} ~ {points}pts</h3>
			<ol>
				{flashcards.map((flashcard, f) => {

					return <li key={f}>
						<div className="FlashcardForms">
							<div className={quizType === "mixture" ? history[f].quizType : quizType/**just make it history? */}>
								<FlashcardForms
									verb={flashcard.verb}
									infinitive={flashcard.infinitive}
									tags={{
										tense: flashcard.tense,
										mood: flashcard.mood,
										person: flashcard.person,
										number: flashcard.number
									}}
									flashcardMode={quizType === "mixture" ? history[f].quizType : quizType}
									choice={""}
									linguisticInfo={linguisticInfo}
									parent="Results"
									answered={true}
									questionNumber={f}
									history={history[f]}
								/>
							</div>
						</div>
					</li>;
				})}
			</ol>
			{parent === "Classroom" ?
				<Link to="/classroom">
					<button>Back to classroom</button>
				</Link> :
				<>
					{parent === "Versus" ? // maybe a better solution for later    // unfilters mail
						<button onClick={() => { props.setShowResults(false); props.refilter(); }}>
							Back
						</button>
						:
						<Link to="/home">
							<button>Home</button>
						</Link>
					}
				</>

			}

		</div>
	);
}

export default Results;