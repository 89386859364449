import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import FlashcardForms from './util/FlashcardForms';
import './styles/FlashcardGrid.scss';
// import './styles/FlashcardForms.scss';
import titles from '../data/titles.json';

const FlashcardGrid = (props) => {

    console.log(props.flashcards)

    const [selectStatus, setSelectStatus] = useState([]);
    const [tabStatus, setTabStatus] = useState(["selectedTab", "tab"]);
    const [flashcardMode, setFlashcardMode] = useState("");

    // Mount
    useEffect(() => {
        if (props.parent === "Home" || props.parent === "Classroom") {
            setFlashcardMode("flip");
        } else if (props.parent === "Search" || props.parent === "VerbAnalyzer") {
            setFlashcardMode("raw");
        }
    }, [props.parent]);

    useEffect(() => {

        setSelectStatus(Array(props.flashcards.length).fill("unselected"));
        // if (props.parent !== "Members")
        props.setChosenCards([]);
        // props.flashcards.length > 0 ? setTabStatus(["selectedTab", "tab"]) : setTabStatus(["tab", "selectedTab"]);
    }, [props.flashcards, props.decks]);

    // Changes the styling so the user is aware of deletion
    function selectToDelete(convert) {
        var selectStatusClone = selectStatus;

        if (convert === true) {
            for (var i of selectStatusClone) {
                if (selectStatusClone[i] === "selected") {
                    selectStatusClone[i] = "delete";
                }
            }

            setSelectStatus([...selectStatusClone]);
        }
    }

    // Adds or removes a card to the deck
    function updateChosenCards(card) {
        var chosenCardsClone = props.chosenCards;
        var selectStatusClone = selectStatus;

        // If card is selected add the card to the deck and sort it
        if (selectStatusClone[card] === "unselected") {
            selectStatusClone[card] = "selected";
            chosenCardsClone.push(props.flashcards[card].id);
            chosenCardsClone.sort();
        } else { // When deselected remove the card from the deck
            selectStatusClone[card] = "unselected";
            chosenCardsClone.splice(chosenCardsClone.indexOf(props.flashcards[card].id), 1);
        }


        setSelectStatus([...selectStatusClone]);
        props.setChosenCards([...chosenCardsClone]);



        console.log(selectStatusClone, chosenCardsClone);
    }

    // Select or deselect all flashcards
    function selectDeselectAll(command) {
        var chosenCardsClone = [];
        var selectStatusClone = selectStatus;

        for (let i = 0; i < props.flashcards.length; i++) {
            selectStatusClone[i] = command;
            if (command === "selected") {
                chosenCardsClone.push(props.flashcards[i].id);
            }
        }

        setSelectStatus(selectStatusClone);
        props.setChosenCards(chosenCardsClone);

        console.log(selectStatusClone, chosenCardsClone);
    }

    const flashcardsList = [];

    if (props.flashcards.length > 0) {
        for (let i = 0; i < props.flashcards.length; i++) {

            flashcardsList.push(
                <div className="FlashcardForms" key={i}>
                    <div
                        className={flashcardMode === "raw" ? selectStatus[i] : flashcardMode}
                        onClick={() => flashcardMode === "raw" && updateChosenCards(i)}
                    >
                        <FlashcardForms
                            verb={props.flashcards[i].verb}
                            infinitive={props.flashcards[i].infinitive}
                            tags={{
                                tense: props.flashcards[i].tense,
                                mood: props.flashcards[i].mood,
                                person: props.flashcards[i].person,
                                number: props.flashcards[i].number
                            }}
                            flashcardMode={flashcardMode}
                            choice={""}
                            linguisticInfo={props.linguisticInfo}
                            parent={"FlashcardGrid"}
                        />
                    </div>
                </div>
            );

            console.log(props.flashcards[i].mood)

            if ((flashcardMode === "conjugate") && (props.flashcards[i].tense === null) &&
                ((props.flashcards[i].mood !== "Indicative") &&
                    (props.flashcards[i].mood !== "Subjunctive") &&
                    (props.flashcards[i].mood !== "Conditional") &&
                    (props.flashcards[i].mood !== "Imperative"))) { // RETHINK THIS

                flashcardsList.splice(-1, 1);
            }

            if ((flashcardMode === "conjugate") && (props.flashcards[i].mood === "Gerund")) {
                flashcardsList.splice(-1, 1)
            }
        }
    }

    var flipMode = flashcardMode === "flip" ? "Mode 1" : "Mode 2"; // Not a strict solution

    return (
        <div className="FlashcardGrid">
            {(props.parent === "Home" || props.parent === "Classroom") &&
                <div className="tabs">
                    <div
                        className={tabStatus[0]}
                        onClick={() => { setFlashcardMode("flip"); setTabStatus(["selectedTab", "tab"]); }}
                    >
                        {titles[props.language].study}
                    </div>
                    <div
                        className={tabStatus[1]}
                        onClick={() => { setFlashcardMode("raw"); setTabStatus(["tab", "selectedTab"]); }}
                    >
                        {props.chosenUserID === -1 &&
                            titles[props.language].edit
                        }
                        {props.chosenUserID > -1 &&
                            titles[props.language].copy
                        }
                    </div>
                </div>
            }
            <div className="toolbar">
                {((flashcardMode === "flip") || (flashcardMode === "flip2") || (flashcardMode === "conjugate")) &&
                    <div className="studyModes">
                        <button
                            className="mode"
                            onClick={() => { selectDeselectAll("unselected"); setFlashcardMode("flip") }}
                        >
                            {titles[props.language].flip_cards}
                        </button>
                        <button
                            className="mode"
                            onClick={() => { selectDeselectAll("unselected"); setFlashcardMode("conjugate") }}
                        >
                            {titles[props.language].spell_conjuagation}
                        </button>
                        {((flashcardMode === "flip") || (flashcardMode === "flip2")) &&
                            <button
                                className="flipAll"
                                onClick={() => flashcardMode === "flip" ? setFlashcardMode("flip2") : setFlashcardMode("flip")}
                            >
                                {flipMode}
                            </button>
                        }
                    </div>
                }
                {flashcardMode === "raw" &&
                    <div className="editMode">
                        <button
                            title="Select All"
                            className="selectAll"
                            disabled={props.flashcards.length === 0}
                            onClick={() => selectDeselectAll("selected")}
                        >
                            {titles[props.language].select_all}
                        </button>
                        <button
                            title="Deselect All"
                            className="deselectAll"
                            disabled={props.flashcards.length === 0}
                            onClick={() => selectDeselectAll("unselected")}
                        >
                            {titles[props.language].deselect_all}
                        </button>
                        {(props.parent === "Home" && props.chosenUserID === -1) &&
                            <Link className="addCards"
                                to={{
                                    pathname: `/search`,
                                    chosenDeck: props.chosenDeck
                                }}
                            >
                                <button>Add cards</button>
                            </Link>
                        }

                    </div>
                }
            </div>
            <div className="list">
                {flashcardsList}
            </div>
        </div>
    );
}

export default FlashcardGrid;