import React, { useState } from 'react';
import { useRanger } from "react-ranger";
import '../styles/Racetrack.scss';

export default function Racetrack(props) {

    const { getTrackProps, handles } = useRanger({
        min: 0,
        max: props.flashcards.length,
        stepSize: 1,
        values: props.players.map(p => p.questionNumber)
        // onChange: setValues
    });

    // function checkSamePlacement(indexToCheck, questionNumber) {
    //     let restOfPlayers = [...props.players.splice(indexToCheck, 1)];
    //     for (let i = 0; i < restOfPlayers.length; i++) {
    //         if (questionNumber === restOfPlayers[i].questionNumber) {
    //             return true;
    //         }
    //     }
    //     return false;
    // }

    return (
        <div className="Racetrack">
            <div className="track" {...getTrackProps()}>
                {handles.map(({ value, active, getHandleProps }, p) => (
                    <button
                        disabled={true}
                        {...getHandleProps({
                            style: {
                                appearance: "none",
                                border: "none",
                                background: "transparent",
                                outline: "none"
                            }
                        })}
                    >
                        {props.players[p].username &&
                            <div className='handle' active={active.toString()}>
                                <span className='status'>{props.players[p].status}</span>
                                <span className='name'
                                    style={{ transform: `rotate(-45deg) translate(${p * 15}px, ${p * 15}px)` }}
                                >
                                    {props.players[p].username} [{props.players[p].points}pts]
                                </span>
                            </div>
                        }
                    </button>
                ))}
            </div>
        </div>
    );
}
