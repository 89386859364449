import React from 'react';
import { Link } from "react-router-dom";
import Label from './util/Label';
import FlashcardForms from './util/FlashcardForms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import './styles/Quiz.scss';
import './styles/FlashcardForms.scss';
import titles from '../data/titles.json';

// Alert message if leaving, say that progress won't be saved
class Quiz extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			correct: 0,
			incorrect: 0,
			points: 0,

			randomType: this.props.location.quizType === "mixture" ? (Math.floor(Math.random() * 2) === 0 ? "conjugate" : "identify_tense") : "none",
			answered: null,
			showChoices: false,
			currCard: -1, // change to questionNumber?
			wrongAnswer: "",

			history: [],

			didYouMean: false,

			result: null,
			choices: [],
			chosenStatus: [],
			choice: "",

			explanation: {},
			explainPage: 0
		};
		this.processResult = this.processResult.bind(this);
		this.getExplanation = this.getExplanation.bind(this);
		this.nextQuestion = this.nextQuestion.bind(this);
		this.getFlashcardFromDeck = this.getFlashcardFromDeck.bind(this);
		this.getRandomFlashcard = this.getRandomFlashcard.bind(this);
		this.mixMultipleChoice = this.mixMultipleChoice.bind(this);
		this.saveChoice = this.saveChoice.bind(this);
		this.navExplainGallery = this.navExplainGallery.bind(this);
		this.answerQuestion = this.answerQuestion.bind(this);
	}

	async componentDidMount() {
		if (this.props.location.chosenDeck) { // If there is data to make a quiz with
			console.log(this.props.location);
			this.setState({ history: Array(this.props.location.flashcards.length).fill({ correct: 0, attempt: "", verbID: -1, quizType: this.props.location.quizType }) });
			this.nextQuestion();
		} else {
			window.location.replace("/home"); // Redirects to homepage on reload
			alert("Your quiz progress has not been saved.");
		}
	}

	// Mix up multiple choice order
	mixMultipleChoice(choices) {
		for (let j = choices.length - 1; j > 0; j--) {
			const k = Math.floor(Math.random() * (j + 1));
			[choices[j], choices[k]] = [choices[k], choices[j]];
		}
		return choices;
	}

	getRandomFlashcard() {
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				form: this.props.location.tagForm
			})
		};
		fetch('/getQuestion', requestOptions)
			.then(response => response.json())
			.then(data => {
				console.log(data);

				let choicesClone = this.mixMultipleChoice([data.choices[0].verb, data.choices[1].verb, data.choices[2].verb]);

				this.setState({
					tense: data.answer.tense_tag,
					infinitive: data.answer.root_tag,
					conjuagtedVerb: data.answer.verb,
					choices: choicesClone,
				});
			}
			);
	}

	getFlashcardFromDeck() {
		const { chosenDeck, flashcards, quizType } = this.props.location;
		const { currCard, randomType } = this.state;

		console.log(currCard);

		/* If this quiz needs a tense value and the verb in question doesn't have one, skip the question
		if (flashcards.tense[i] === null) {
			this.setState({i: i + 1}); AWAIT
		}*/

		// If the deck hasn't been gone through yet
		if (currCard < chosenDeck.size) {
			// If the question is asking to conjugate the verb
			if ((quizType === "conjugate") || (randomType === "conjugate")) {
				let choicesClone = []

				const requestOptions = {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({
						infinitive: flashcards[currCard].infinitive
					})
				};
				fetch('/api/getWrongChoices', requestOptions)
					.then(response => response.json())
					.then(data => {
						console.log(data);

						choicesClone = this.mixMultipleChoice([data.choices[0].verb, data.choices[1].verb, flashcards[currCard].verb]);

						this.setState({ choices: choicesClone });

						//console.log("ANSWER: ", flashcards[currCard].verb);
					}
					);
			} // If question is asking for the tense of a conjugated verb
			else if ((quizType === "identify_tense") || (randomType === "identify_tense")) {
				let choicesClone = [];
				let randomTenseIndex;

				choicesClone.push(flashcards[currCard].tense);

				// Get wrong answers
				while (choicesClone.length !== 3) {
					randomTenseIndex = Math.floor(Math.random() * Math.floor(this.props.linguisticInfo.tense.length));
					choicesClone.push(this.props.linguisticInfo.tense[randomTenseIndex].tag_name);
					choicesClone = [...new Set(choicesClone)]; // Remove duplicate values
				}

				choicesClone = this.mixMultipleChoice(choicesClone); // Mix up the values
				// console.log(choicesClone);

				this.setState({
					choices: choicesClone,
					showChoices: true
				});

				//console.log("ANSWER: ", flashcards[currCard].tense);
			}
		}

		// Get random question type for the next question
		//if (quizType === "mixture") {
		//Math.floor(Math.random() * 2) === 0 ? this.setState({ randomType: "conjugate" }) : this.setState({ randomType: "identify_tense" });
		//}
	}

	getExplanation() {
		const { quizType, flashcards } = this.props.location;
		const { randomType, currCard } = this.state;

		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				wrongAnswer: this.state.wrongAnswer,
				quizType: quizType === "mixture" ? randomType : quizType, // CHOOSE ONE VERSION OF THIS CONDITIONAL (flashcard forms conditional)
				infinitive: flashcards[currCard].infinitive
			})
		};
		fetch('/api/getExplanation', requestOptions)
			.then(response => response.json())
			.then(data => {
				console.log(data);

				let explanationClone = { tense: [], mood: [], person: [], number: [], infinitive: [], verb: [], id: [] };

				// Loop through all the generated verbs
				for (let i = 0; i < data.explanation.length; i++) {
					// Store attributes of current verb form
					explanationClone.tense[i] = data.explanation[i].tense_tag;
					explanationClone.mood[i] = data.explanation[i].mood_tag;
					explanationClone.person[i] = data.explanation[i].person_tag;
					explanationClone.number[i] = data.explanation[i].number_tag;
					explanationClone.infinitive[i] = data.explanation[i].root_tag;
					explanationClone.verb[i] = data.explanation[i].verb;
					explanationClone.id[i] = data.explanation[i].id;
				}

				// Update what flashcards to display
				this.setState({
					explanation: explanationClone,
					didYouMean: explanationClone.verb.length === 0 && true
				}, () => {
					console.log(this.state.didYouMean, explanationClone.verb)
				});
			}
			);
	}

	// Process data from the child that describes if the user got the answer right
	processResult(result, wrongAnswer) { // change wrongAnswer variable name to potentialAnswer (seperate from state version)
		return new Promise((resolve, reject) => {
			const { flashcards, quizType } = this.props.location;
			const { currCard } = this.state;

			let chosenStatusClone = this.state.chosenStatus;
			let currentHistory = [...this.state.history];

			// add quiztype to history
			if (result === true) {
				let rewardedPoints = 100;

				chosenStatusClone[chosenStatusClone.findIndex((status) => status === "checked")] = "correct";

				currentHistory[currCard] = { correct: 1, attempt: wrongAnswer, verbID: flashcards[currCard].id, quizType: quizType };
				if (quizType === "mixture") currentHistory[currCard]['quizType'] = this.state.randomType;

				if ((quizType === "conjugate" || this.state.randomType === "conjugate") && this.state.showChoices) rewardedPoints = 50;

				this.setState({
					wrongAnswer: "",
					correct: this.state.correct + 1,
					points: this.state.points + rewardedPoints,
					chosenStatus: chosenStatusClone,
					history: [...currentHistory]
				});

				resolve(currentHistory);
			} else if (result === false) {
				let answer;

				currentHistory[currCard] = { correct: 0, attempt: wrongAnswer, verbID: flashcards[currCard].id, quizType: quizType };
				if (quizType === "mixture") currentHistory[currCard]['quizType'] = this.state.randomType;

				if ((quizType === "conjugate") || (this.state.randomType === "conjugate")) {
					answer = flashcards[currCard].verb;
				}

				else if ((quizType === "identify_tense") || (this.state.randomType === "identify_tense")) {
					answer = flashcards[currCard].tense;
				}



				for (var i = 0; i < chosenStatusClone.length; i++) {
					if ((chosenStatusClone[i] === "unchecked") && (this.state.choices[i] === answer)) {
						chosenStatusClone[i] = "correct";
					}
				}

				chosenStatusClone[chosenStatusClone.findIndex((status) => status === "checked")] = "incorrect";
				this.setState({
					wrongAnswer: wrongAnswer,
					incorrect: this.state.incorrect + 1,

					chosenStatus: chosenStatusClone,
					history: [...currentHistory]
				}, () => {
					this.getExplanation();
				});
				resolve(currentHistory);
			}




		})
	}

	// Saves what the user chose from the multiple-choices
	saveChoice(c) {
		let chosenStatusClone = ["unchecked", "unchecked", "unchecked"];
		chosenStatusClone[c] = "checked";

		this.setState({
			chosenStatus: chosenStatusClone,
			choice: this.state.choices[c]
		});

		console.log(this.state.choices[c])
	}

	// Navigates the explanation gallery
	navExplainGallery(movement) {
		if ((this.state.explainPage + movement) < this.state.explanation.id.length &&
			(this.state.explainPage + movement) > -1) {
			this.setState({ explainPage: this.state.explainPage + movement });
		}
	}

	setChoice(newChoice) {
		this.setState({ choice: newChoice })
	}

	answerQuestion() {
		this.setState({ answered: true })
	}

	// Goes to the next question in the quiz
	nextQuestion() {
		const { flashcards, quizType } = this.props.location;

		this.setState({
			choice: "",
			didYouMean: false,
			explanation: {},
			explainPage: 0,
			wrongAnswer: "",
			showChoices: false,
			answered: false,
			currCard: this.state.currCard + 1,
			chosenStatus: ["unchecked", "unchecked", "unchecked"],
			randomType: this.props.location.quizType === "mixture" ? (Math.floor(Math.random() * 2) === 0 ? "conjugate" : "identify_tense") : "none"
		}, () => {
			// Runs after states are updated above
			if (((quizType === "identify_tense") || (this.state.randomType === "identify_tense")) && flashcards[this.state.currCard].tense === null) {
				this.nextQuestion();
			} else if (((quizType === "conjugate") || (this.state.randomType === "conjugate")) && (flashcards[this.state.currCard].tense === null) &&
				((flashcards[this.state.currCard].mood !== "Indicative") &&
					(flashcards[this.state.currCard].mood !== "Subjunctive") &&
					(flashcards[this.state.currCard].mood !== "Conditional") &&
					(flashcards[this.state.currCard].mood !== "Imperative"))) {
				this.nextQuestion();
			}
			else {
				this.props.location.chosenDeck ? this.getFlashcardFromDeck() : this.getRandomFlashcard();
			}
		});
	}

	render() {
		const { quizType, tagForm, chosenDeck, flashcards } = this.props.location;
		const { currCard, answered, explanation, history } = this.state;

		var explanationGallery = [];

		const choices = [] // Generate HTML the multiple choices
		for (let c = 0; c < this.state.choices.length; c++) {
			choices.push(
				<li
					key={c}
					onClick={() => answered === false && this.saveChoice(c)}
				>
					<div
						className={this.state.chosenStatus[c]}
					>
						{((quizType === "conjugate") || (this.state.randomType === "conjugate")) &&
							`${this.state.choices[c]}`
						}
						{((quizType === "identify_tense") || (this.state.randomType === "identify_tense")) &&
							<Label
								linguisticInfo={this.props.linguisticInfo}
								tags={{ tense: this.state.choices[c] }}
							/>
						}
					</div>
				</li>
			);
		}

		// Generates the explanation gallery
		if (explanation.id) {
			for (let i = 0; i < explanation.id.length; i++) {
				explanationGallery.push(
					<div className="gallery" key={i}>
						<div className="FlashcardForms">
							<div className="raw">
								<FlashcardForms
									verb={explanation.verb[i]}
									infinitive={explanation.infinitive[i]}
									tags={{
										tense: explanation.tense[i],
										mood: explanation.mood[i],
										person: explanation.person[i],
										number: explanation.number[i]
									}}
									flashcardMode="raw"
									linguisticInfo={this.props.linguisticInfo}
								/>
							</div>
						</div>
					</div>
				);
			}
		}
		//edit dinstance, leven strin, keyboard, semantic distance

		return (
			<div className="Quiz">
				{chosenDeck !== undefined && currCard > -1 &&
					<div className="select">
						<div className="quizhead">
							<h3>{tagForm}{chosenDeck.name}</h3>
							<div className="score">
								Score: {this.state.points}
							</div>
							{(((quizType === "conjugate") || (this.state.randomType === "conjugate")) && (this.state.showChoices === false)) &&
								<button onClick={() => this.setState({ showChoices: true })}>
									{titles[this.props.language].hint}
								</button>
							}
						</div>
						<div className="question">
							{this.state.wrongAnswer.length > 0 &&
								<div className="explanation">
									{((quizType === "conjugate") || (this.state.randomType === "conjugate")) &&
										<div>
											{this.state.didYouMean
												?
												<div>
													<b>{this.state.wrongAnswer}</b> is not a word or is spelled wrong.
												</div>
												:
												<div>
													<b>{this.state.wrongAnswer}</b> can be:
													<br />{explanationGallery[this.state.explainPage]}
												</div>
											}
										</div>
									}

									{((quizType === "identify_tense") || (this.state.randomType === "identify_tense")) &&
										<div>
											<b>{flashcards[currCard].infinitive}</b> in <b> <Label
												linguisticInfo={this.props.linguisticInfo}
												tags={{ tense: this.state.wrongAnswer }}
											/></b> can be:
											<br />{explanationGallery[this.state.explainPage]}
										</div>
									}
									<div className="pages">
										<FontAwesomeIcon
											icon={faArrowCircleLeft}
											title="Previous"
											className="previousButton"
											onClick={() => this.navExplainGallery(-1)}
										/>
										<b>{this.state.explainPage + 1} / {explanationGallery.length}</b>
										<FontAwesomeIcon
											icon={faArrowCircleRight}
											title="Next"
											className="nextButton"
											onClick={() => this.navExplainGallery(1)}
										/>
									</div>
								</div>
							}
							<div className="card-container">
								<div className="FlashcardForms">
									<div className={this.state.randomType === "none" ? quizType : this.state.randomType}>
										<FlashcardForms
											verb={flashcards[currCard].verb}
											infinitive={flashcards[currCard].infinitive}
											tags={{
												tense: flashcards[currCard].tense,
												mood: flashcards[currCard].mood,
												person: flashcards[currCard].person,
												number: flashcards[currCard].number
											}}
											flashcardMode={this.state.randomType === "none" ? quizType : this.state.randomType}
											choice={this.state.choice}
											linguisticInfo={this.props.linguisticInfo}
											parent="Quiz"
											answered={answered}
											answerQuestion={this.answerQuestion}
											questionNumber={currCard}
											processResult={this.processResult}
										/>
									</div>
								</div>
							</div>
							<ul
								className="choices"
								style={this.state.showChoices === true ? {} : { display: 'none' }}
							>
								{choices}
							</ul>
						</div>
						<div className="continue">
							{(((quizType === "identify_tense" || this.state.randomType === "identify_tense") && this.state.choice.length > 0) ||
								(quizType === "conjugate" || this.state.randomType === "conjugate")) && !answered &&
								<button
									onClick={() => this.answerQuestion()}
								>
									{titles[this.props.language].check}
								</button>
							}
							<button
								className={this.state.chosenStatus.includes("incorrect") ? "next" : "next-correct"}
								style={answered === true && currCard < chosenDeck.size - 1 ? {} : { display: 'none' }}
								onClick={() => this.nextQuestion()}
							>
								{titles[this.props.language].next}
							</button>
							{history[history.length - 1].verbID !== -1 &&
								<Link
									to={{
										pathname: "/results",
										history: history,
										flashcards: flashcards,
										linguisticInfo: this.props.linguisticInfo,
										quizType: quizType,
										chosenDeck: chosenDeck,
										class_id: this.props.location.class_id,
										parent: this.props.location.parent,
										correct: this.state.correct,
										points: this.state.points
									}}
									style={answered === true && currCard === chosenDeck.size - 1 ? {} : { display: 'none' }}
								>
									<button>{titles[this.props.language].finish}</button>
								</Link>
							}
						</div>
					</div>
				}
			</div>
		);
	}
}

export default Quiz;