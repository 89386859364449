import React from 'react';
import DeckSelection from './DeckSelection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import './styles/Search.scss';
import titles from '../data/titles.json';

class Analyze extends React.Component {
    constructor() {
        super();
        this.state = {
            verb: "",
            flashcards: []
        };
        this.handleChange = this.handleChange.bind(this);
        this.analyze = this.analyze.bind(this);
    }

    // Make global later????
    // Saves the current state of email and password
    handleChange(event) {
        const { placeholder, value } = event.target
        this.setState({ [placeholder]: value });
    }

    // Analyzes the verb, tense, person
    analyze() {
        if (this.state.verb !== "") {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    verb: this.state.verb
                })
            };
            fetch('/api/getVerbAnalysis', requestOptions)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    var flashcardsClone = [];

                    for (var i = 0; i < data.analysis.length; i++) {
                        flashcardsClone.push({
                            tense: data.analysis[i].tense_tag,
                            mood: data.analysis[i].mood_tag,
                            person: data.analysis[i].person_tag,
                            number: data.analysis[i].number_tag,
                            infinitive: data.analysis[i].root_tag,
                            verb: data.analysis[i].verb,
                            id: data.analysis[i].id
                        });
                    }
                    // Update the current analysis
                    this.setState({ flashcards: flashcardsClone });
                    //console.log(data)
                })
                .catch(error => console.error("Fetch operation failed.", error));
        } else {
            alert("Fill in the verb");
        }
    }

    render() {
        return (
            <div className="Search">
                <div className="select">
                    <div className="instructions">
                        {titles[this.props.language].verb_analyzer_instructions}
                    </div>
                    <div className="inputVerb">
                        <input
                            type="text"
                            size="lg"
                            placeholder="verb"
                            value={this.state.verb}
                            onChange={this.handleChange}
                            onKeyPress={event => (event.keyCode || event.which === 13) && this.analyze()}
                        />
                        <FontAwesomeIcon
                            icon={faSearch}
                            className="searchButton"
                            title="Search"
                            onClick={() => this.analyze()}
                        />
                    </div>
                </div>
                <div>
                    {this.state.flashcards.length > 0 &&
                        <DeckSelection
                            language={this.props.language}
                            chosenUserID={-1}
                            flashcards={this.state.flashcards}
                            linguisticInfo={this.props.linguisticInfo}
                            accountType={this.props.accountType}
                            parent="VerbAnalyzer"
                        />
                    }
                </div>
            </div>
        )
    }
}

export default Analyze;