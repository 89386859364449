import React from 'react';
import './styles/Linguistics.scss';

class Linguistics extends React.Component {
    constructor() {
        super();
        this.state = {
          username: "",
          loggedIn: false
        };
    }
    
    componentDidMount() {
        fetch('/api/getLinguisticInfo')
            .then(response => response.json())
            .then(data => {
                console.log(data);
                this.setState({
                    
                });
            }
        );
    }
    
    render(){
        return (
            // Please reconsider the styling of this page, I was just playing around with it
            <div className="Linguistics">
                <div className="select">
                    <h4>tense</h4>
                    <p> 
                        Tense is a grammatical means to indicate the time when an action or event occurs. 
                        As such, tense expresses whether an action or event happened in the past (past tense), 
                        will happen in the future (future tense) or is happening right now (present tense).
                    </p>
                    <h4>mood</h4>
                    <p>
                        Mood is a grammatical means that allows speakers to express their attitude towards their utterance 
                        (e.g a statement of fact, of desire, of command, etc). 
                    </p>
                    <h4>person</h4>
                    <p>
                        Mood is a grammatical means that allows speakers to express their attitude towards their utterance 
                        (e.g a statement of fact, of desire, of command, etc). 
                    </p>
                    <h4>number</h4>
                    <p>
                        Mood is a grammatical means that allows speakers to express their attitude towards their utterance 
                        (e.g a statement of fact, of desire, of command, etc). 
                    </p>   
                </div>
            </div>
        )
    };
}

export default Linguistics;
