import React from 'react';
import '../styles/Auth.scss';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

class Register extends React.Component {
    constructor() {
        super();
        this.state = {
            email: "",
            username: "",
            language: "tag_en",
            accountType: "student",
            password: "",
            confirmedPassword: ""
        };
        this.handleChange = this.handleChange.bind(this);
        this.registerUser = this.registerUser.bind(this);
    }

    // Saves the current state of email and password
    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    // Register the user
    async registerUser() {
        if ((this.state.password.length > 0) &&
            (this.state.confirmedPassword.length > 0) &&
            (this.state.email.length > 0) &&
            (this.state.username.length > 0)) {

            if (this.state.password === this.state.confirmedPassword) {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        email: this.state.email,
                        username: this.state.username,
                        lang: this.state.language,
                        account_type: this.state.accountType,
                        password: this.state.password
                    })
                };
                await fetch('/api/registerUser', requestOptions);
                window.location.replace("/login");
            } else {
                alert("Passwords don't match.");
            }
        }
        else {
            alert("Enter all fields.");
        }
    }

    render() {
        return (
            <div className="Auth">
                <form>
                    <h2>Register</h2>
                    <div>
                        <label>Email</label>
                        <input
                            type="email"
                            name="email"
                            value={this.state.email}
                            placeholder='Enter email'
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <label>Username</label>
                        <input
                            type="text"
                            name="username"
                            value={this.state.username}
                            placeholder='Enter username'
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <label>Join as</label>
                        <select
                            value={this.state.accountType}
                            onChange={event => this.setState({ accountType: event.target.value })}
                        >
                            <option value="student">Student</option>
                            <option value="teacher">Teacher</option>
                        </select>
                    </div>
                    <div>
                        <label>Language</label>
                        <select
                            value={this.state.language}
                            onChange={event => this.setState({ language: event.target.value })}
                        >
                            <option value="tag_en">English</option>
                            <option value="tag_de">German</option>
                        </select>
                    </div>
                    <div>
                        <label>Password</label>
                        <input
                            type="password"
                            name="password"
                            value={this.state.password}
                            placeholder="Password"
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <label>Confirm Password</label>
                        <input
                            type="password"
                            name="confirmedPassword"
                            value={this.state.confirmedPassword}
                            placeholder="Confirm Password"
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <Button onClick={this.registerUser}>Register</Button>
                    </div>
                    <div className="link">
                        Already a member? <Link to="login">Click to login</Link>
                    </div>
                </form>
            </div>
        );
    }
}

export default Register;