import React from 'react';
import { Route, Redirect } from 'react-router-dom';

// Checks if the user is logged in, if not the user is redirected to the login page
const PrivateRoute = (props) => (
    <Route 
        render={() => (
            props.isLoggedIn === true
            ? <props.component {...props} />
            : <Redirect to={{
                pathname: '/login',
                state: { from: props.location }
            }}/>
        )}
    />
);

export default PrivateRoute;