import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
//import QuickQuiz from './QuickQuiz';
import Explore from './Explore';
import DeckSelection from './DeckSelection';
import './styles/Home.scss';
// import titles from '../data/titles.json';

const Home = (props) => {
	const [chosenUserID, setChosenUserID] = useState(-1); // -1 defaults to you
	const [isExploring, setIsExploring] = useState(false);

	return (
		<>
			<div className="Home">
				<div className="view">
					<span
						className="viewOption"
						style={{ backgroundColor: !isExploring && "hsl(255, 90%, 92%)" }}
						onClick={() => {
							setIsExploring(false);
							setChosenUserID(-1);
						}}
					>
						{props.username}
					</span>
					<span
						className="viewOption"
						style={{ backgroundColor: isExploring && "hsl(255, 90%, 92%)" }}
						onClick={() => setIsExploring(true)}
					>
						Explore
					</span>
				</div>
				{isExploring ?
					<Explore
						isExploring={isExploring}
						setIsExploring={setIsExploring}
						chosenUserID={chosenUserID}
						setChosenUserID={setChosenUserID}
						language={props.language}
						linguisticInfo={props.linguisticInfo}
						accountType={props.accountType}
						parent="Home"
					/>
					:
					<DeckSelection
						language={props.language}
						chosenUserID={chosenUserID}
						linguisticInfo={props.linguisticInfo}
						accountType={props.accountType}
						parent="Home"
					/>
				}
			</div>
		</>
	);
}

// <QuickQuiz
// 	linguisticInfo={props.linguisticInfo}
// 	language={props.language}
// />

export default Home;