// Returns the form of a verb
const Label = (props) => {
    var verbForm = ""; 
    var attributes = ["tense", "mood", "person", "number"] // Specifies the order to loop through the attributes

    // Loop through linguistic attributes - tense, mood, person, number
    for (let a in attributes) {
        // Loop through the forms they offer
        for (let form in props.linguisticInfo[attributes[a]]) {
            if (props.tags[attributes[a]] === props.linguisticInfo[attributes[a]][form].tag_name) {
                verbForm += props.linguisticInfo[attributes[a]][form]["tag_it"] + " ";
            }
        }
    }

    return verbForm;
}

export default Label;