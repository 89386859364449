import React from 'react';
import './styles/Nav.scss';
import { NavLink } from 'react-router-dom';
import titles from '../data/titles.json';

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faUserCircle } from '@fortawesome/free-solid-svg-icons'


const Nav = (props) => {
	return (
		<nav className="Nav">
			<span className="logo">Card-IT</span>
			<span className="menu" style={props.isLoggedIn === true ? {} : { display: 'none' }}>
				<NavLink to="/home" exact activeClassName="selected">{titles[props.language].home}</NavLink>
				<NavLink to="/versus" exact activeClassName="selected">Versus</NavLink>
				{/* <NavLink to="/classroom" exact activeClassName="selected">Classroom</NavLink> */}
				<NavLink to="/analyze" exact activeClassName="selected">{titles[props.language].verb_analyzer}</NavLink>
				<NavLink to="/search" exact activeClassName="selected">{titles[props.language].verb_search}</NavLink>
				{/* <NavLink to="/linguistics" exact activeClassName="selected">{titles[props.language].linguistics}</NavLink> */}
				<NavLink to="/feedback" exact activeClassName="selected">Feedback</NavLink>
			</span>
			<span className="options">
				<NavLink
					to="/account"
					id="account"
					style={props.isLoggedIn === true ? {} : { display: 'none' }}
				>
					{props.username}
				</NavLink>
				<select
					value={props.language}
					onChange={props.languageChange}
				>
					<option value="tag_en">English</option>
					<option value="tag_de">German</option>
				</select>
				<button
					style={props.isLoggedIn === true ? {} : { display: 'none' }}
					onClick={() => props.handleLogout()}
				>
					{titles[props.language].logout}
				</button>
			</span>
		</nav>
	);
}

export default Nav;