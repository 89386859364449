import React, { useState, useEffect, useRef } from 'react';
// import { Link } from "react-router-dom";
import io from 'socket.io-client';
import QuizM2 from './QuizM2';
import Rules from './QuizM/Rules';
import Results from './Results';
import DeckSelection from './DeckSelection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import './styles/Versus.scss';
import titles from '../data/titles.json';
// import Racetrack from './QuizM/Racetrack';

const Versus = (props) => {
    const [chosenDecks, setChosenDecks] = useState([]);
    const [flashcardsUnfiltered, setFlashcardsUnfiltered] = useState([]);
    const [flashcards, setFlashcards] = useState([]);
    const [start, setStart] = useState(false);
    const [roomCode, setRoomCode] = useState("");
    const [quizType, setQuizType] = useState("conjugate");
    const [gameType, setGameType] = useState("Same deck(s)"); // race, send, coop
    const [itemFromOpponent, setItemFromOpponent] = useState("");

    const [quizResults, setQuizResults] = useState({ history: [], points: 0, correct: 0 });

    const [showRules, setShowRules] = useState(false);
    const [showResults, setShowResults] = useState(false);

    const [recievedMailOrItems, setRecievedMailOrItems] = useState([]); // transfered

    const [openDeckSelection, setOpenDeckSelection] = useState(false);

    const [time, setTime] = useState(10);
    const [timeTrialTime, setTimeTrialTime] = useState(5);

    const [players, setPlayers] = useState([]);
    const [playerID, setPlayerID] = useState("");

    const socketRef = useRef();

    const playerIDRef = useRef();
    const roomCodeRef = useRef();

    playerIDRef.current = playerID;
    roomCodeRef.current = roomCode;

    useEffect(() => {
        socketRef.current = io.connect(process.env.REACT_APP_IP, { path: process.env.REACT_APP_PATH });

        return () => {
            leaveRoom();
            socketRef.current.disconnect();
            // useRef
        }
    }, []);

    useEffect(() => {
        socketRef.current.on('new room', (joinedOrCreated, roomCode, updatedPlayers) => {
            console.log(joinedOrCreated, roomCode);
            setPlayers(updatedPlayers);

            if (playerID === "") {
                console.log(playerID)
                setPlayerID(updatedPlayers[updatedPlayers.length - 1].socketID);
                setRoomCode(roomCode);
            }
        });

        socketRef.current.on('decks', (chosenDecks, flashcards) => {// rename params
            console.log(chosenDecks, flashcards);
            setChosenDecks(chosenDecks);
            setFlashcards(flashcards);
        });
        socketRef.current.on('filteredFlashcards', (filteredFlashcards) => { setFlashcards(filteredFlashcards); });

        socketRef.current.on('item', (newItemFromOpponent, recievedMailOrItems = []) => {
            setItemFromOpponent(newItemFromOpponent);
            setRecievedMailOrItems(recievedMailOrItems);

            console.log(recievedMailOrItems)
        });

        socketRef.current.on('updatePlayers', (updatedPlayers) => { setPlayers(updatedPlayers); });
        socketRef.current.on('gameStart', (start) => { setStart(start); });
        socketRef.current.on('gameType', (gameType) => {
            setGameType(gameType);
            setFlashcardsUnfiltered([]);
            setChosenDecks([])
            setFlashcards([]);
        });
        socketRef.current.on('quizType', (quizType) => { setQuizType(quizType); });

    }, [socketRef.current]);

    useEffect(() => {
        refilter();
    }, [quizType, gameType]); // Refilter flashcards when quizType changes

    function refilter() {
        if (flashcardsUnfiltered.length > 0 && playerIDRef.current !== "") {
            let filteredFlashcards = removeFlashcardsWithLackingAttributes(flashcardsUnfiltered)
            setFlashcards(filteredFlashcards);
            if (gameType === "Same deck(s)") {
                socketRef.current.emit('filterFlashcards', filteredFlashcards, roomCode);
            }
        }
    }

    function leaveRoom() { /// might need more work
        console.log(playerIDRef.current, roomCodeRef.current)
        socketRef.current.emit('leave room', playerIDRef.current, roomCodeRef.current);
        socketRef.current = io.connect(process.env.REACT_APP_IP, { path: process.env.REACT_APP_PATH });
        setPlayers([]);
        setPlayerID("");
        setChosenDecks([]);
        setFlashcards([]);
        setQuizType("conjugate")
        setRoomCode("");
    }

    useEffect(() => {
        if (time > 0) {
            let timeTimeout = setTimeout(() => setTime(time - 1), 1000);
            return () => clearTimeout(timeTimeout);
        }
    });

    useEffect(() => {
        if (timeTrialTime > 0) {
            let timeTrialTimeout = setTimeout(() => setTimeTrialTime(timeTrialTime - 1), 1000);
            return () => clearTimeout(timeTrialTimeout);
        }
    });

    // Updates the decks and flashcards used in the game
    function updateMultiplayerFlashcards(deck) {
        let chosenDecksCopy = [...chosenDecks];
        let newFlashcards = [];

        if (chosenDecks.some(cd => cd.id === deck.id)) {
            chosenDecksCopy = chosenDecksCopy.filter(cd => cd.id !== deck.id);
            setChosenDecks(chosenDecksCopy);
        } else {
            chosenDecksCopy.push(deck);
            setChosenDecks(chosenDecksCopy);
        }

        chosenDecksCopy.forEach(d => newFlashcards.push(...d.flashcards));

        // Make sure all values of newFlashcards are unique based on their id
        // Avoids adding duplicate flashcards, possibility arises from the fact that the same flashcard can be in multiple decks
        newFlashcards = newFlashcards.filter((item, index, array) => array.findIndex(i => i.id === item.id) === index);

        setFlashcardsUnfiltered(newFlashcards); // Save all flashcards to filter them in different ways depending on the quiz type

        newFlashcards = removeFlashcardsWithLackingAttributes(newFlashcards);

        // Remove flashcards that aren't compatible with the quiz type and mix them up
        setFlashcards(newFlashcards);

        // console.log(chosenDecksCopy, newFlashcards)

        if (gameType === "Same deck(s)") {

            socketRef.current.emit('decks', chosenDecksCopy, newFlashcards, roomCode);
        }
    }

    function removeFlashcardsWithLackingAttributes(cardsToFilter) {
        let newFlashcards = [];

        if (quizType === "identify_tense") {
            newFlashcards = cardsToFilter.filter(f => f.tense !== null);
        } else if (quizType === "conjugate") {
            newFlashcards = cardsToFilter.filter(f =>
                f.tense !== null && (f.mood === "Indicative" || f.mood === "Subjunctive" || f.mood === "Conditional" || f.mood === "Imperative"));
        }

        console.log(newFlashcards, cardsToFilter)

        return newFlashcards
    }

    function mixFlashcards() {
        let flashcardsToMix = [...flashcards];
        for (let j = flashcardsToMix.length - 1; j > 0; j--) {
            const k = Math.floor(Math.random() * (j + 1));
            [flashcardsToMix[j], flashcardsToMix[k]] = [flashcardsToMix[k], flashcardsToMix[j]];
        }

        setFlashcards([...flashcardsToMix]);

        // if (gameType === "Same deck(s)") {
        //     socketRef.current.emit('filterFlashcards', flashcardsToMix, roomCode);
        // }
    }

    function createRoom() {
        socketRef.current.emit('create room', props.username);
    }

    function finishGame({ ...newQuizResults }) {
        setStart(false);

        console.log({ ...newQuizResults })

        setQuizResults({ ...newQuizResults });

        setShowResults(true);
    }


    function joinRoomCallback(settings) {
        if (settings.roomCode.length > 0) {
            setFlashcards(settings.flashcards);
            setChosenDecks(settings.chosenDecks);
            setQuizType(settings.quizType);
            setGameType(settings.gameType);
        } else {
            alert("This room doesn't exist.");
        }
    }



    function _useOffenseItem(itemType, give = []) {
        console.log(give)
        socketRef.current.emit('item', itemType, give, playerID, roomCode);
    }

    function updateRacetrack(questionNumber, status, points) {
        // console.log(questionNumber, status)
        socketRef.current.emit('updateRacetrack', questionNumber, status, points, flashcards.length, playerID, roomCode);
    }

    return (
        <>
            {/* <span>{time}</span> */}
            {/* <Racetrack
                players={players}
                flashcards={flashcards}
            /> */}
            {!start &&
                <div className="Versus">
                    <div className="select">
                        <div className="head">
                            <div className='corner-area'>
                                <FontAwesomeIcon
                                    className="back"
                                    style={{ visibility: playerID === "" && "hidden" }}
                                    icon={faArrowCircleLeft}
                                    title="Back"
                                    onClick={() => leaveRoom()}
                                />
                            </div>
                            <h1>Versus</h1>
                            <div className='corner-area'>
                                <button onClick={() => setShowRules(true)}>
                                    Rules
                                </button>
                            </div>
                        </div>
                        {playerID === "" &&
                            <div className="versus-home">
                                <div className="join-room-path">
                                    <input
                                        placeholder="Room code"
                                        onChange={e => setRoomCode(e.target.value)}
                                        value={roomCode}
                                    />
                                    <button onClick={() =>
                                        socketRef.current.emit('join room', props.username, roomCode, (settings) => joinRoomCallback(settings))}
                                    >
                                        Join room
                                    </button>
                                </div>
                                <div className="create-room-path">
                                    <button onClick={() => createRoom()}>Create room</button>
                                </div>
                            </div>
                        }
                        {playerID !== "" &&
                            <div className="room">
                                <h2 onClick={() => console.log(roomCode)}>Room code: <b>{roomCode}</b></h2>
                                {players.length > 0 &&
                                    <ul className="player-list">
                                        {players.sort((a, b) => b.points - a.points).map((player, i) => { // sort according to points
                                            let placement = i + 1;
                                            return (
                                                <li key={i}>
                                                    <span className='placement'>
                                                        {placement < 4 ?
                                                            <>
                                                                {placement === 1 && <>{placement}st</>}
                                                                {placement === 2 && <>{placement}nd</>}
                                                                {placement === 3 && <>{placement}rd</>}
                                                            </>
                                                            :
                                                            <>{placement}th</>
                                                        }
                                                    </span>
                                                    &nbsp;- {player.username} {player.isHost && <>(Host)</>} - {player.points}pts - {player.status}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                }
                                <div className="settings">
                                    <div className="deck-list">
                                        <div onClick={() => console.log()}>
                                            {(players[players.findIndex(p => p.socketID === playerIDRef.current)]?.isHost || gameType === "Different Deck(s)") ?
                                                <button onClick={() => setOpenDeckSelection(!openDeckSelection)}>Select Deck(s)</button>
                                                :
                                                <h4>Deck(s)</h4>
                                            }
                                        </div>
                                        <ul className='chosen-deck-names'>
                                            {chosenDecks.map((deck, d) => <li key={d}>{deck.name}</li>)}
                                            <li className='number-of-cards'>({flashcards.length}) cards</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <div className="quizType">
                                            <h4>Quiz Type</h4>
                                            {players[players.findIndex(p => p.socketID === playerIDRef.current)]?.isHost ?
                                                <div className="select-mode">
                                                    {['conjugate', 'identify_tense'].map((quizOption, q) => //  , 'mixture'
                                                        <button key={q}
                                                            className={quizType === quizOption ? "chosen" : ""}
                                                            onClick={() => socketRef.current.emit('quizType', quizOption, roomCode)}
                                                        >
                                                            {titles[props.language][quizOption]}
                                                        </button>
                                                    )}
                                                </div>
                                                :
                                                <div className="chosen-mode">
                                                    {titles[props.language][quizType]}
                                                </div>
                                            }
                                        </div>
                                        <div className="race-with">
                                            <h4>Play with</h4>
                                            {players[players.findIndex(p => p.socketID === playerIDRef.current)]?.isHost ?
                                                <div className="select-mode">
                                                    {['Same deck(s)', 'Different Deck(s)'].map((gameOption, m) => // co-op
                                                        <button key={m}
                                                            className={gameType === gameOption ? "chosen" : ""}
                                                            onClick={() => socketRef.current.emit('gameType', gameOption, roomCode)}
                                                        >
                                                            {gameOption}
                                                        </button>
                                                    )}
                                                </div>
                                                :
                                                <div className="chosen-mode">
                                                    {gameType}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {
                                    players[players.findIndex(p => p.socketID === playerIDRef.current)]?.isHost ?
                                        <>
                                            <button
                                                disabled={!players.every(p => p.isReady === true) || (flashcards.length === 0) || players.length < 2}
                                                onClick={() => {
                                                    // mixFlashcards();
                                                    socketRef.current.emit('gameStart', true, roomCode)
                                                }}
                                            >
                                                Start game
                                            </button>
                                            <div className="ready-and-waiting">
                                                {(players.every(p => p.isReady === true) && players.length > 1) ? <>Player(s) are ready</> : <>Waiting for player(s) to be ready...</>}
                                            </div>
                                        </>
                                        :
                                        <>
                                            <button onClick={() => socketRef.current.emit("ready", playerID, roomCode)}>
                                                {players[players.findIndex(p => p.socketID === playerID)]?.isReady ? <>Not ready</> : <>Ready</>}
                                            </button>
                                            <div className="ready-and-waiting">
                                                {players[players.findIndex(p => p.socketID === playerID)]?.isReady && <>Waiting for host to begin game...</>}
                                            </div>
                                        </>
                                }
                            </div>
                        }
                    </div>
                </div>
            }
            {start &&
                <QuizM2
                    quizType={quizType}
                    chosenDecks={chosenDecks}
                    flashcards={flashcards}
                    setFlashcards={setFlashcards}
                    language={props.language}
                    linguisticInfo={props.linguisticInfo}
                    finishGame={finishGame}
                    _useOffenseItem={_useOffenseItem}
                    itemFromOpponent={itemFromOpponent}
                    setItemFromOpponent={setItemFromOpponent}
                    time={time}
                    timeTrialTime={timeTrialTime}
                    setTime={setTime}
                    setTimeTrialTime={setTimeTrialTime}
                    players={players}
                    playerID={playerID}
                    updateRacetrack={updateRacetrack}
                    username={props.username}
                    recievedMailOrItems={recievedMailOrItems}
                    setRecievedMailOrItems={setRecievedMailOrItems}
                    gameType={gameType}
                    mixFlashcards={mixFlashcards}
                />
            }
            {(players[players.findIndex(p => p.socketID === playerIDRef.current)]?.isHost || gameType === "Different Deck(s)") && !start && openDeckSelection &&
                <div className='DSoverlay'>
                    <DeckSelection
                        parent={"Versus"}
                        chosenDecks={chosenDecks}
                        language={props.language}
                        setOpenDeckSelection={setOpenDeckSelection}
                        updateMultiplayerFlashcards={updateMultiplayerFlashcards}
                        chosenUserID={-1}
                    />
                </div>
            }
            {showRules &&
                <Rules
                    setShowRules={setShowRules}
                />
            }
            {showResults &&
                <div className="DSoverlay">
                    <Results
                        linguisticInfo={props.linguisticInfo}
                        history={quizResults.history}
                        points={quizResults.points}
                        correct={quizResults.correct}
                        setShowResults={setShowResults}
                        flashcards={flashcards}
                        quizType={quizType}
                        refilter={refilter}
                        parent="Versus"
                    />
                </div>
            }
        </>
    );
}

export default Versus;

// maybe implement later
// { "name": "kaboom", "icon": "☄","status": "😱", "type": "offense", "spell_question": false, "description": "The craziest attack" }
// { "name": "x-ray", "icon": "🥽", "status": "🤓", "type": "assist", "spell_question": true, "description": "See what letters are correct as you are typing" },
// { "name": "study", "icon": "🎓", "status": "🤓", "type": "assist", "spell_question": false, "description": "Study 9 random cards from your deck for 5 seconds" }
// { "name": "steal+", "icon": "👿","status": "😩", "type": "offense", "spell_question": false, "description": "Steals two items from opponent" }