import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEraser } from '@fortawesome/free-solid-svg-icons';
import useKeyPress from '../util/useKeyPress';

const Inventory = (props) => {

    const [activeSlots, setActiveSlots] = useState([true, true]);

    useEffect(() => {
        switch (props.itemFromOpponent.name) {
            case "lock":
                props.applyItemAndUpdateStatus(props.itemFromOpponent);

                let random = Math.floor(Math.random() * 2);
                random === 0 ? setActiveSlots([true, false]) : setActiveSlots([false, true]);
                setTimeout(() => {
                    setActiveSlots([true, true]);
                }, 25000);
                break;
        }
    }, [props.itemFromOpponent]);

    function _useItem(i) {
        if (props.inventory[i] && activeSlots[i]) { // If item exists and slot is active
            if (props.inventory[i].type === 'assist') props._useAssistItem(props.inventory[i], i);
            else props._useOffenseItem(props.inventory[i], [props.currentFlashcard]);
            props.removeFromInventory(i);
        }
    }

    const onKeyPress = (event) => {
        switch (event.key) {
            case '1':
                _useItem(0);
                break;
            case '2':
                _useItem(1);
                break;
            default:
                break;
        }
    };

    useKeyPress(['1', '2'], onKeyPress);

    return (
        <div>
            <h4>Items</h4>
            <ul className="Inventory">
                {activeSlots.map((activeSlot, i) => {
                    // console.log(props.inventory[i])
                    if (props.inventory[i]) {
                        return <li key={i}
                            className='item'
                            style={{ backgroundColor: props.inventory[i].type === "assist" ? "lightgreen" : "lightcoral" }}
                            onClick={() => _useItem(i)}
                        >
                            <div
                                className="icon"
                                style={{ opacity: activeSlot ? '1' : '0.5' }}
                            >
                                {props.inventory[i].icon === 'erase' ?
                                    <FontAwesomeIcon icon={faEraser} /> : <>{props.inventory[i]?.icon}</>
                                }
                            </div>
                            {!activeSlot ?
                                <div className="lock">🔒</div> :
                                <div className="name">{props.inventory[i]?.name?.toUpperCase()}</div>
                            }

                        </li>
                    }
                    return <li key={i} className='item'></li>
                })}
            </ul>
        </div>
    );
}

export default Inventory;