import React, { useState, useEffect, useRef } from 'react';
// import { Link } from "react-router-dom";
import Label from './util/Label';
import FlashcardForms from './util/FlashcardForms';
import Racetrack from './QuizM/Racetrack';
import Inventory from './QuizM/Inventory';
// import ItemTest from './QuizM/ItemTest';
import GetItem from './QuizM/GetItem';
// import StudyItem from './QuizM/StudyItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEraser } from '@fortawesome/free-solid-svg-icons';
import './styles/QuizM.scss';
import './styles/FlashcardForms.scss';
import titles from '../data/titles.json';
import items from '../data/items.json';

const QuizM2 = (props) => {
    const [correct, setCorrect] = useState(0);
    const [incorrect, setIncorrect] = useState(0);
    const [questionNumber, setQuestionNumber] = useState(-1);
    const [points, setPoints] = useState(0);

    const [inventory, setInventory] = useState([]);
    const [bet, setBet] = useState(0);
    const [timeTrial, setTimeTrial] = useState(false);
    const [timeTrialItem, setTimeTrialItem] = useState(null);

    const [randomType, setRandomType] = useState(props.quizType === "mixture" ? (Math.floor(Math.random() * 2) === 0 ? "conjugate" : "identify_tense") : "none");
    const [answered, setAnswered] = useState(null);
    const [showChoices, setShowChoices] = useState(false);
    const [wrongAnswer, setWrongAnswer] = useState("");
    const [didYouMean, setDidYouMean] = useState(false);

    const [newIcon, setNewIcon] = useState("");

    const [skipOrSubmit, setSkipOrSubmit] = useState("Skip");

    // const [result, setResult] = useState(null);
    const [choices, setChoices] = useState([]);
    const [chosenStatus, setChosenStatus] = useState([]);
    const [choice, setChoice] = useState("");

    const [freezed, setFreezed] = useState(false);
    const [hiding, setHiding] = useState(false);
    const [spin, setSpin] = useState(false);
    const [block, setBlock] = useState(false);

    // const [freezedTime, setFreezedTime] = useState(0);
    // const [winner, setWinner] = useState(false);

    const [explanation, setExplanation] = useState({});
    const [explainPage, setExplainPage] = useState(0);

    const [history, setHistory] = useState([]);
    const [animateOn, setAnimateOn] = useState(false);

    const questionNumberRef = useRef();
    const pointsRef = useRef();
    const correctRef = useRef();

    questionNumberRef.current = questionNumber;
    pointsRef.current = points;
    correctRef.current = correct;


    useEffect(() => {
        if (props.chosenDecks) {
            props.mixFlashcards();
            props.updateRacetrack(questionNumberRef.current, '😐', points);
            setHistory(Array(props.flashcards.length).fill({ correct: 0, attempt: "", verbID: -1, quizType: props.quizType }));
            nextQuestion();
        } else {
            window.location.replace("/home"); // Redirects to homepage on reload
            alert("Your quiz progress has not been saved.");
        }
        return;
    }, []);

    useEffect(() => {
        if (questionNumber > -1) {
            distributeItem(); // await?
            props.setTime(10);
            if (timeTrial == true) props.setTimeTrialTime(5);
            console.log(questionNumber, 9009)
            // Skips next question if it lacks the attributes needed to be answered
            // if (((props.quizType === "identify_tense") || (randomType === "identify_tense")) && props.flashcards[questionNumber].tense === null) {
            //     setHistory([...history.splice(questionNumber, 1)]);
            //     if (questionNumber === props.flashcards.length - 1) props.finishGame({ history: [...history], points: points, correct: correct });
            //     else nextQuestion();
            // } else if (((props.quizType === "conjugate") || (randomType === "conjugate")) && (props.flashcards[questionNumber].tense === null) && (
            //     (props.flashcards[questionNumber].mood !== "Indicative") &&
            //     (props.flashcards[questionNumber].mood !== "Subjunctive") &&
            //     (props.flashcards[questionNumber].mood !== "Conditional") &&
            //     (props.flashcards[questionNumber].mood !== "Imperative"))) {
            //     setHistory([...history.splice(questionNumber, 1)]);
            //     if (questionNumber === props.flashcards.length - 1) props.finishGame({ history: [...history], points: points, correct: correct });
            //     else nextQuestion();
            // }
            //else
            getFlashcardFromDeck();
        }
        // else props.chosenDeck ? getFlashcardFromDeck() : getRandomFlashcard(); // function getRandomFlashcard() add later?
    }, [questionNumber]);

    useEffect(() => {
        let status = '😐';
        if (hiding) status = "❓";

        props.updateRacetrack(questionNumberRef.current, status, points);
    }, [points, questionNumber]);

    useEffect(() => { // Recieve items or flashcards from opponent
        console.log(props.itemFromOpponent, props.recievedMailOrItems)

        switch (props.itemFromOpponent.name) {
            case "recieveItems":
                // console.log(props.recievedMailOrItems)
                if (props.recievedMailOrItems.length == 2) {
                    setInventory([...props.recievedMailOrItems]);
                } else if (props.recievedMailOrItems.length == 1) {
                    // console.log(23234)
                    if (inventory.length < 2) setInventory([...inventory, props.recievedMailOrItems[0]]);
                    else if (inventory.length === 2) setInventory([inventory[0], props.recievedMailOrItems[0]]);
                }
                // props.setRecievedMailOrItems([]);
                // props.setItemFromOpponent({})
                break;
            case "mail":
                if (block) {
                    setBlock(false);

                } else if (props.recievedMailOrItems[0]) {
                    let flashcardsClone = [...props.flashcards]
                    flashcardsClone.splice(questionNumber, 0, props.recievedMailOrItems[0]);

                    console.log(flashcardsClone, props.recievedMailOrItems[0])
                    // Update flashcards and the history size
                    props.setFlashcards(flashcardsClone)
                    setHistory([...history.splice(questionNumber, 0, { correct: 0, attempt: "", verbID: -1, quizType: props.quizType })]);

                    applyItemAndUpdateStatus(props.itemFromOpponent);
                }
                // props.setRecievedMailOrItems([]);
                // props.setItemFromOpponent({})
                break;

        }
    }, [props.itemFromOpponent, props.recievedMailOrItems]);

    useEffect(() => { // Get sabotaged
        if (block) {
            setBlock(false);
            animateItem(props.itemFromOpponent.icon);
        } else {
            let give;
            applyItemAndUpdateStatus(props.itemFromOpponent);
            switch (props.itemFromOpponent.name) { // erase, unscramble, ruler, flame are in FlashcardForms
                case 'steal':
                    // let random = Math.floor(Math.random() * inventory.length);
                    give = [inventory[0]];
                    removeFromInventory(0);
                    // console.log([...inventory.splice(0, 1)]);
                    props._useOffenseItem({ name: 'recieveItems', type: 'offense', icon: "", status: "😉" }, give);
                    break;
                case 'steal+':
                    give = [...inventory];
                    setInventory([]);
                    props._useOffenseItem({ name: 'recieveItems', type: 'offense', icon: "", status: "😉" }, give);
                    break;
                case "freeze":
                    setFreezed(true);
                    setTimeout(() => {
                        setFreezed(false);
                    }, 5000)
                    break;
                case "spin":
                    setSpin(true);
                    break;
                default:
                    break;
            }
        }
    }, [props.itemFromOpponent]);

    function applyItemAndUpdateStatus(item) {
        let status = item.status;
        if (hiding) status = "❓";
        if (item.type === "offense") animateItem(item.icon);
        props.updateRacetrack(questionNumberRef.current, status, points);
    }

    function animateItem(icon) {
        setNewIcon(icon);
        setAnimateOn(true)
        let timer = setTimeout(() => setAnimateOn(false), 1500)
        return () => clearTimeout(timer);
    }

    function _useAssistItem(item) {
        applyItemAndUpdateStatus(item);
        switch (item.name) {
            case "skip":
                setPoints(points + 200);
                answerQuestion();
                // setCorrect(correct + 1);
                break;
            case "ruler":
                props.setItemFromOpponent(item)
                break;
            case "hint":
                setShowChoices(true);
                break;
            case "unscramble":
                props.setItemFromOpponent(item)//not really rename to where its applied??
                break;
            case "block":
                setBlock(true);
                break;
            case "hide":
                setHiding(true);
                setTimeout(() => {
                    props.updateRacetrack(questionNumberRef.current, '😐', points);
                    setHiding(false);
                }, 25000)
                break;
        }
    }

    async function distributeItem() { // Item distribution
        if (bet > 0) {
            setPoints(points - bet);
            setBet(0);
        }

        if (inventory.length < 2) { // If there are less than 2 items in inventory
            let itemTier;
            let chanceOfItemDrop;

            // Get player placement
            let sortPlayersByPlacement = props.players.sort((a, b) => a.points - b.points);
            let placement = sortPlayersByPlacement.findIndex(p => p.socketID === props.playerID);
            let placementToPercent = (placement + 1) / props.players.length;

            if (placementToPercent >= 0.75) {
                itemTier = "D";
                chanceOfItemDrop = 5; // 20% chance of getting item
            } else {
                chanceOfItemDrop = 3; // 33.33...% chance of getting item
                if (placementToPercent >= 0.5) itemTier = "C";
                else if (placementToPercent >= 0.25) itemTier = "B";
                else {
                    chanceOfItemDrop = 2; // 50% chance of getting item
                    itemTier = "A";
                }
            }

            if (bet > 0 && (history[questionNumber - 1].correct === 1)) { // If a bet was placed and the player answered correctly
                chanceOfItemDrop -= (bet / 100);
                if (chanceOfItemDrop < 1) chanceOfItemDrop = 1;
                // console.log("b")
                // if (bet === 400) chanceOfItemDrop = 1; // Will get an item for sure
                // else if (bet === 200) chanceOfItemDrop = 2; // 50% chance of getting item

            }

            const dropChance = Math.floor(Math.random() * chanceOfItemDrop);

            if (dropChance === 0) {
                if (props.gameType === "different decks") {
                    let mailChance = Math.floor(Math.random() * 3); // 1/3 chance of getting mail instead
                    if (mailChance === 0) itemTier = "M";
                }

                // let itemsInTier = items["M"]; // for mail testing
                let itemsInTier = items[itemTier]; // If the quiz type is identify tense remove the possibility of getting items that exclusively help the conjugate quiz 
                if (props.quizType === "identify_tense") itemsInTier = itemsInTier.filter(item => item.spell_question === false);

                // Generate item in tier
                let newItem = itemsInTier[Math.floor(Math.random() * itemsInTier.length)];

                if (inventory.length < 2) setInventory([...inventory, newItem]); // Add item to inventory if it's not full
            }
        }
    }

    function removeFromInventory(i) {
        let inventoryClone = [...inventory];
        inventoryClone.splice(i, 1);
        setInventory(inventoryClone);
    }

    function answerQuestion() { // 
        setAnswered(true);

        // disable certain item effects

        setShowChoices(false);
    }

    function transitionToNextQuestion(res) {
        if (questionNumber < props.flashcards.length - 1) {
            setTimeout(() => nextQuestion(), 1000); // Run nextQuestion() after 1 second so user knows if they answered correctly
        } else {
            setTimeout(() => {
                props.finishGame({ history: [...res], points: pointsRef.current, correct: correctRef.current });
                setQuestionNumber(questionNumber + 1)
                props.updateRacetrack(questionNumberRef.current + 1, '🚩', pointsRef.current);
            }, 1000); // Finish game once the end is reached
        }

        //props.setItemFromOpponent("");
    }

    function nextQuestion() {
        if (props.quizType === "conjugate") {

            let doTimeTrial = false;

            if ((Math.floor(Math.random() * 8) === 0)) doTimeTrial = true; // 1/8 chance of time trial
            //doTimeTrial = true // for testing it every turn

            if (doTimeTrial) {
                setTimeTrial(true);

                // random tier between B to D
                let randomTier = ["B", "C", "D"][Math.floor(Math.random() * 3)];
                setTimeTrialItem(items[randomTier][Math.floor(Math.random() * items[randomTier].length)]);
            }
        }
        setSpin(false);

        // console.log(props.players)
        setDidYouMean(false);
        setSkipOrSubmit("Skip");
        setChoice("");

        setExplanation({});
        setExplainPage(0);
        setWrongAnswer("");

        setAnswered(false);
        setQuestionNumber(questionNumber + 1);
        setChosenStatus(["unchecked", "unchecked", "unchecked"]);
        setRandomType(props.quizType === "mixture" ? (Math.floor(Math.random() * 2) === 0 ? "conjugate" : "identify_tense") : "none");
    }

    // Mix up multiple choice order
    function mixMultipleChoice(choices) {
        for (let j = choices.length - 1; j > 0; j--) {
            const k = Math.floor(Math.random() * (j + 1));
            [choices[j], choices[k]] = [choices[k], choices[j]];
        }
        return choices;
    }

    function getFlashcardFromDeck() {
        const { flashcards, quizType } = props;

        // console.log(questionNumber);
        /* If this quiz needs a tense value and the verb in question doesn't have one, skip the question
        if (flashcards.tense[i] === null) {
            this.setState({i: i + 1}); AWAIT
        }*/

        // If the deck hasn't been gone through yet
        if (questionNumber < flashcards.length) {
            // If the question is asking to conjugate the verb
            if ((quizType === "conjugate") || (randomType === "conjugate")) {
                let choicesClone = []

                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ infinitive: flashcards[questionNumber].infinitive })
                };
                fetch('/api/getWrongChoices', requestOptions)
                    .then(response => response.json())
                    .then(data => {
                        console.log(data);
                        choicesClone = mixMultipleChoice([data.choices[0].verb, data.choices[1].verb, flashcards[questionNumber].verb]);
                        setChoices(choicesClone);
                        //console.log("ANSWER: ", flashcards[questionNumber].verb);
                    }
                    );
            } // If question is asking for the tense of a conjugated verb
            else if ((quizType === "identify_tense") || (randomType === "identify_tense")) {
                let choicesClone = [];
                let randomTenseIndex;

                choicesClone.push(flashcards[questionNumber].tense);

                // Get wrong answers
                while (choicesClone.length !== 3) {
                    randomTenseIndex = Math.floor(Math.random() * Math.floor(props.linguisticInfo.tense.length));
                    choicesClone.push(props.linguisticInfo.tense[randomTenseIndex].tag_name);
                    choicesClone = [...new Set(choicesClone)]; // Remove duplicate values
                }

                choicesClone = mixMultipleChoice(choicesClone); // Mix up the values
                console.log(choicesClone);

                setChoices(choicesClone);
                setShowChoices(true);
                //console.log("ANSWER: ", flashcards[questionNumber].tense);
            }
        }
    }

    function getExplanation() {
        const { quizType, flashcards } = props;

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                wrongAnswer: wrongAnswer,
                quizType: quizType === "mixture" ? randomType : quizType, // CHOOSE ONE VERSION OF THIS CONDITIONAL (flashcard forms conditional)
                infinitive: flashcards[questionNumber].infinitive
            })
        };
        fetch('/api/getExplanation', requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log(data);

                let explanationClone = { tense: [], mood: [], person: [], number: [], infinitive: [], verb: [], id: [] };

                // Loop through all the generated verbs
                for (let i = 0; i < data.explanation.length; i++) {
                    // Store attributes of current verb form
                    explanationClone.tense[i] = data.explanation[i].tense_tag;
                    explanationClone.mood[i] = data.explanation[i].mood_tag;
                    explanationClone.person[i] = data.explanation[i].person_tag;
                    explanationClone.number[i] = data.explanation[i].number_tag;
                    explanationClone.infinitive[i] = data.explanation[i].root_tag;
                    explanationClone.verb[i] = data.explanation[i].verb;
                    explanationClone.id[i] = data.explanation[i].id;
                }

                setExplanation(explanationClone); // Update what flashcards to display
                setDidYouMean(explanationClone.verb.length === 0 && true);
            });
    }

    // Process data from the child that describes if the user got the answer right
    function processResult(result, newWrongAnswer) {
        return new Promise((resolve, reject) => {
            const { flashcards, quizType } = props;
            let chosenStatusClone = [...chosenStatus];
            let currentHistory = [...history];

            if (result) {
                chosenStatusClone[chosenStatusClone.findIndex((status) => status === "checked")] = "correct";

                // update history
                currentHistory[questionNumber] = { correct: 1, attempt: newWrongAnswer, verbID: flashcards[questionNumber].id, quizType: quizType };
                if (quizType === "mixture") currentHistory[questionNumber]['quizType'] = randomType;

                if (timeTrial && props.time > 5 && inventory.length < 2) {
                    setTimeTrial(false);
                    setInventory([...inventory, timeTrialItem]);
                }

                setWrongAnswer("1")
                setCorrect(correct + 1)
                setPoints(props.time <= 1 ? points + 100 : points + (100 * props.time))
                setChosenStatus(chosenStatusClone)
                setHistory([...currentHistory]);

                resolve(currentHistory);

            } else if (!result) {
                let answer;

                // update history
                currentHistory[questionNumber] = { correct: 0, attempt: newWrongAnswer, verbID: flashcards[questionNumber].id, quizType: quizType };
                if (quizType === "mixture") currentHistory[questionNumber]['quizType'] = randomType;

                if ((quizType === "conjugate") || (randomType === "conjugate")) answer = flashcards[questionNumber].verb;
                else if ((quizType === "identify_tense") || (randomType === "identify_tense")) answer = flashcards[questionNumber].tense;

                for (let i = 0; i < chosenStatusClone.length; i++) {
                    if ((chosenStatusClone[i] === "unchecked") && (choices[i] === answer)) {
                        chosenStatusClone[i] = "correct";
                    }
                }

                chosenStatusClone[chosenStatusClone.findIndex((status) => status === "checked")] = "incorrect";

                setWrongAnswer(newWrongAnswer);
                setIncorrect(incorrect + 1);
                setChosenStatus(chosenStatusClone);
                setHistory([...currentHistory]);

                //getExplanation(); // MIGHT NOT WORK IN NORMAL QUIZ, refer to old version
                resolve(currentHistory);
            }
        });
    }

    // Saves what the user chose from the multiple-choices
    function saveChoice(c) {
        let chosenStatusClone = ["unchecked", "unchecked", "unchecked"];
        chosenStatusClone[c] = "checked";

        setChosenStatus(chosenStatusClone);
        setChoice(choices[c]);
        setSkipOrSubmit("Submit")

        console.log(choice.length, props.quizType)
    }

    // Navigates the explanation gallery
    function navExplainGallery(movement) {
        if ((explainPage + movement) < explanation.id.length &&
            (explainPage + movement) > -1) {
            setExplainPage(explainPage + movement);
        }
    }

    const { quizType, tagForm, flashcards } = props;

    let explanationGallery = [];

    // Generates the explanation gallery
    if (explanation.id) {
        for (let i = 0; i < explanation.id.length; i++) {
            explanationGallery.push(
                <div className="gallery" key={i}>
                    <div className="FlashcardForms">
                        <div className="raw">
                            <FlashcardForms
                                verb={explanation.verb[i]}
                                infinitive={explanation.infinitive[i]}
                                tags={{
                                    tense: explanation.tense[i],
                                    mood: explanation.mood[i],
                                    person: explanation.person[i],
                                    number: explanation.number[i]
                                }}
                                flashcardMode="raw"
                                linguisticInfo={props.linguisticInfo}
                            />
                        </div>
                    </div>
                </div>
            );
        }
    }

    return (
        <>
            {animateOn &&
                <div className='animate'>
                    {newIcon === "erase" ? <FontAwesomeIcon icon={faEraser} /> : newIcon}
                </div>
            }
            <div className={!freezed ? `QuizM` : "Frozen-Quiz"}>
                {props.chosenDecks !== undefined && questionNumber > -1 &&
                    <div className={`select`} style={block ? { border: "solid 4px lightcoral" } : {}}>
                        <Racetrack
                            players={props.players}
                            flashcards={flashcards}
                        />
                        {/* <div>{props.time}</div>
                        <div>{props.timeTrialTime}</div> */}
                        <div className="quizhead">
                            <h3>{tagForm}{/*chosenDeck.name*/}</h3>
                            <div className="test-game">
                                {/* {props.players.map((player, p) =>
                                    <button key={p}>{player.name}</button>
                                )} */}
                            </div>
                            <div className="score">
                                Score: {points}
                                {/* {titles[props.language].correct}: {correct}
									<br />
									{titles[props.language].incorrect}: {incorrect} */}
                            </div>
                            {/* {(((quizType === "conjugate") || (randomType === "conjugate")) && (showChoices === false)) &&
									<button onClick={() => this.setState({ showChoices: true })}>
										{titles[props.language].hint}
									</button>
								} */}
                        </div>
                        <div className="question">
                            <Inventory
                                inventory={inventory}
                                itemFromOpponent={props.itemFromOpponent}
                                _useOffenseItem={props._useOffenseItem}
                                _useAssistItem={_useAssistItem}
                                removeFromInventory={removeFromInventory}
                                currentFlashcard={props.flashcards[questionNumber]}
                                applyItemAndUpdateStatus={applyItemAndUpdateStatus}
                                questionNumber={questionNumber}
                            />
                            {/**explanation */}
                            <div className="card-container">
                                <div className={`FlashcardForms ${spin && "spin-flashcard"}`}>
                                    <div className={
                                        `${randomType === "none" ? quizType : randomType}
                                         ${(answered && wrongAnswer === "1") && "is-correct"} 
                                         ${(answered && wrongAnswer !== "1") && "is-incorrect"}`
                                    }>
                                        <FlashcardForms
                                            verb={flashcards[questionNumber].verb}
                                            infinitive={flashcards[questionNumber].infinitive}
                                            tags={{
                                                tense: flashcards[questionNumber].tense,
                                                mood: flashcards[questionNumber].mood,
                                                person: flashcards[questionNumber].person,
                                                number: flashcards[questionNumber].number
                                            }}
                                            flashcardMode={randomType === "none" ? quizType : randomType}
                                            choice={choice}
                                            linguisticInfo={props.linguisticInfo}
                                            parent="Quiz"
                                            setAnswered={setAnswered}
                                            answered={answered}
                                            transitionToNextQuestion={transitionToNextQuestion}
                                            questionNumber={questionNumber}
                                            game={true}
                                            processResult={processResult}
                                            item={props.itemFromOpponent}
                                            block={block}
                                            setBlock={setBlock}
                                            applyItemAndUpdateStatus={applyItemAndUpdateStatus}
                                            setSkipOrSubmit={setSkipOrSubmit}
                                        />
                                    </div>
                                </div>
                            </div>
                            {showChoices || quizType === "identify_tense" ?
                                <ul className="choices">
                                    {choices.map((choice, c) =>
                                        <li key={c} onClick={() => !answered && saveChoice(c)}>
                                            <div className={chosenStatus[c]}>
                                                {((quizType === "conjugate") || (randomType === "conjugate")) && `${choice}`}
                                                {((quizType === "identify_tense") || (randomType === "identify_tense")) &&
                                                    <Label linguisticInfo={props.linguisticInfo} tags={{ tense: choice }} />
                                                }
                                            </div>
                                        </li>
                                    )}
                                </ul>
                                :
                                <GetItem
                                    bet={bet}
                                    setBet={setBet}
                                    points={points}
                                    time={props.timeTrialTime}
                                    timeTrial={timeTrial}
                                    timeTrialItem={timeTrialItem}
                                    distributeItem={distributeItem}
                                    skipOrSubmit={skipOrSubmit}
                                />
                            }

                        </div>
                        <div className="continue">
                            {/* <button /// Kept incase of merge with normal quiz component for future
                                style={!answered ? {} : { display: 'none' }}
                                onClick={() => setAnswered(true)}
                            >
                                {titles[props.language].check}
                            </button> */}
                            {((quizType === "identify_tense" && choice.length > 0) || quizType === "conjugate") &&
                                <button
                                    className={chosenStatus.includes("incorrect") ? "next" : "next-correct"}
                                    style={answered ? { opacity: '0.7' } : {}}
                                    disabled={answered}
                                    onClick={() => answerQuestion()} // triggers isCorrect() in FlashcardForms
                                >
                                    {questionNumber < flashcards.length - 1 ?
                                        <>
                                            {skipOrSubmit}
                                            {((bet > 0) && (skipOrSubmit === "Submit")) && <> + Bet</>}
                                        </>
                                        :
                                        <>{titles[props.language].finish}</>}
                                </button>
                            }
                        </div>
                    </div>
                }
            </div>
            {/* <StudyItem /> */}
            {/* <ItemTest _useAssistItem={_useAssistItem} _useOffenseItem={props._useOffenseItem} /> */}
        </>
    )
}

export default QuizM2;