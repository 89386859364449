import React from 'react';
import '../styles/Auth.scss';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            username: ""
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSuccessfulLogin = this.handleSuccessfulLogin.bind(this);
    }

    // Saves the current state of email and password
    handleChange(event) {
        const {type, value} = event.target
        this.setState({
            [type]: value
        })
    }

    // Login user if their credentials match
    handleSuccessfulLogin() {
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                email: this.state.email, 
                password: this.state.password
            })
        };
        fetch('/api/login', requestOptions)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    username: data.username
                })
                // If the user exists go to homepage and save user data for this session
                if (data.username != null) {
                    //console.log(data)
                    this.props.storeUserData(data);
                    this.props.history.push("/home");
                }
            }
        );
    }

    render() {
        return (
            <div className="Auth">
                <form>
                    <h2>Login</h2>
                    <div>
                        <input
                            type="email" 
                            value={this.state.email}
                            placeholder="Email" 
                            onChange={this.handleChange} 
                            required
                        />
                    </div>
                    <div>
                        <input
                            type="password" 
                            value={this.state.password}
                            placeholder="Password" 
                            onChange={this.handleChange} 
                            required
                        />
                    </div>    
                    <div>
                        <Button 
                            onClick={this.handleSuccessfulLogin}
                        >
                            Login
                        </Button>
                    </div>
                    <div className="link">
                        Not registered? <Link to="/register">Create account</Link>
                    </div>
                </form>
            </div>
        );
    }
}

export default Login;