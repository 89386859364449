import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEraser } from '@fortawesome/free-solid-svg-icons';

export default function GetItem(props) {

    return (
        <div className="GetItem">
            <h4>Get Item</h4>
            <div className="bet">
                <div className='title'>Bet points</div>
                <div className='points-to-bet' style={{ opacity: (props.points > 0) ? "100%" : "50%" }}>
                    <button className="minus" onClick={() => { if (props.bet > 0) props.setBet(props.bet - 100) }}>
                        -
                    </button>
                    <span className="points">{props.bet}</span>
                    <button className="plus"
                        disabled={props.skipOrSubmit === "Skip" ? true : false}
                        onClick={() => { if (props.bet < props.points) props.setBet(props.bet + 100) }}>
                        +
                    </button>
                </div>
            </div>
            <div className='time'>
                <div className='title'>Time trial</div>

                <div className='countdown'
                    style={{ opacity: (props.timeTrial && props.time > 0) ? "100%" : "50%" }}
                >
                    <span className='count'>{(props.timeTrial && props.time > 0) ? <>{props.time}</> : <>0</>}</span>
                    {props.timeTrial &&
                        <span className='icon'>
                            - {props.timeTrialItem?.icon === 'erase' ?
                                <FontAwesomeIcon icon={faEraser} /> : <>{props.timeTrialItem?.icon}</>
                            }
                        </span>
                    }
                </div>
            </div>
        </div>
    )
}
