import React from 'react';
import '../styles/ConfirmDelete.scss';

const ConfirmDelete = (props) => {

    async function deleteType() {
        const deleteFunc = props.objectToDelete.type === 'collection' ? '/api/deleteCollection' : '/api/deleteDeck';

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id: props.objectToDelete.id })
        };
        const response = await fetch(deleteFunc, requestOptions);
        if (!response.ok) return;

        // If the chosen deck is being deleted unchoose it
        if ((props.objectToDelete.type === 'deck') && 
            (props.objectToDelete.id === props.chosenDeck.id)) {
            props.setChosenDeck({ id: -1 });
        }

        props.setObjectToDelete({ name: "", id: -1, type: "" });
        props.getCollections();
    }

    return (
        <div className="ConfirmDelete">
            <div className="window">
                <div className="areYouSure">
                    Are you sure you want to delete {props.objectToDelete.name}?
                </div>
                <button onClick={() => deleteType()}>
                    Yes
                </button>
                <button onClick={() => props.setObjectToDelete({ name: "", id: -1, type: "" })}>
                    No
                </button>
            </div>
        </div>
    );
}

export default ConfirmDelete;