import React from 'react';
import DeckSelection from './DeckSelection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import './styles/Search.scss';
import titles from '../data/titles.json';

class VerbSearch extends React.Component {
    constructor() {
        super();
        this.state = {
            verb: "",
            checks: {tense: [], mood: [], person: [], number: []},
            flashcards: []
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
        this.generateTags = this.generateTags.bind(this);
    }    

    // Get label data and initialize them as unchecked
    componentDidMount() {
        const initialUncheck = this.state.checks;
                
        Object.entries(this.props.linguisticInfo).forEach(([attributes]) => {
            initialUncheck[attributes] = Array(this.props.linguisticInfo[attributes].length).fill("unchecked");
        });

        this.setState({ checks: initialUncheck });
    }

    generateTags(checksList) {
        // Store what the user checked and inputted
        let tags = {tense: [], mood: [], person: [], number: []};
        
        // Loop through checksList tense, mood, person, number
        Object.entries(checksList).forEach(([attributes]) => {
            // Loop through attributes of the current list of checksList
            Object.entries(checksList[attributes]).forEach(([form]) => {
                // If the checkbox is checked append tag info
                if (this.state.checks[attributes][form] === "checked") {
                    tags[attributes].push(this.props.linguisticInfo[attributes][form]["tag_name"]);
                }
            });
        });

        console.log(tags)

        // Fetch flashcard data
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ 
                verb: this.state.verb,
                tags: tags
            })
        };
        fetch('/api/verbSearch', requestOptions)
            .then(response => response.json())
            .then(data => {
                // Empty flashcards
                let flashcardsClone = [];

                // Loop through all the generated verbs
                for (let i=0; i < data.analysis.length; i++) {
                    // Store attributes of current verb form
                    flashcardsClone.push({
						tense: data.analysis[i].tense_tag, 
						mood: data.analysis[i].mood_tag, 
						person: data.analysis[i].person_tag, 
						number: data.analysis[i].number_tag, 
						infinitive: data.analysis[i].root_tag, 
						verb: data.analysis[i].verb, 
						id: data.analysis[i].id
					});
                }

                // Update what flashcards to display
                this.setState ({ flashcards: flashcardsClone }); 
                
                console.log(this.state.flashcards);
            }
        );
    }

    // Changes the state of checked and unchecked boxes
    handleCheckChange(attributes, a) {
        let checksClone = {...this.state.checks} // Clone checks for manipulation

        if (checksClone[attributes][a] === "unchecked") {
            checksClone[attributes][a] = "checked";
        } else {
            checksClone[attributes][a] = "unchecked"; 
        }
        this.setState ({ checks: checksClone }); // Update state of checks
        console.log(this.state.checks)
    }

    // Global?
    // Saves state of verb input
    handleChange(event) {
        const {placeholder, value} = event.target;
        this.setState({
            [placeholder]: value
        })
    }
  
    render() {
        const checksList = {tense: [], mood: [], person: [], number: []}; // Show the check list HTML

        // Loop through every linguistic attribute
        Object.entries(this.props.linguisticInfo).forEach(([attribute]) => {
            // Loop through the labels for every linguistic attribute to generate HTML based on selected language
            Object.entries(this.props.linguisticInfo[attribute]).forEach(([form]) => {
                // Append every label to the list
                checksList[attribute].push(
                    <li key={form}>
                        <div
                            value={this.state.checks[attribute][form]}
                            className={this.state.checks[attribute][form]}
                            onClick={() => this.handleCheckChange(attribute, form)}
                        >
                            {this.props.linguisticInfo[attribute][form]["tag_it"]} 
                        </div>
                    </li>
                );
            });
        });

        return (
            <div className="Search">
                <div className="select">
                    <div className="attributeLists">
                        <ul>
                            <li><h1 title="Tense?">{titles[this.props.language].tense}</h1></li>
                            {checksList.tense}
                        </ul>
                        <ul>
                            <li><h1 title="What is mood?">{titles[this.props.language].mood}</h1></li>
                            {checksList.mood}
                        </ul>
                        <ul>
                            <li><h1 title="What is person?">{titles[this.props.language].person}</h1></li>
                            {checksList.person}
                        </ul>
                        <ul>
                            <li><h1 title="What is number?">{titles[this.props.language].number}</h1></li>
                            {checksList.number}
                        </ul>
                    </div>
                    <div className="inputVerb">
                        <input
                            placeholder="verb" 
                            name="verb"
                            value={this.state.verb}
                            onChange={this.handleChange}
                            onKeyPress={event => (event.keyCode || event.which === 13) && this.generateTags(checksList)}
                        />  
                        <FontAwesomeIcon 
                            icon={faSearch}
                            className="searchButton"
                            title="Search"
                            onClick={() => this.generateTags(checksList)}
                        />
                    </div>
                </div>
                <DeckSelection
                    language={this.props.language}
                    chosenUserID={-1}
                    flashcards={this.state.flashcards}
                    linguisticInfo={this.props.linguisticInfo}
                    accountType={this.props.accountType}
                    chosenDeckLink={this.props.location.chosenDeck}
                    parent="Search"
                />
            </div>
        )
    }
}

export default VerbSearch;