import React from 'react';
import { Link } from 'react-router-dom';
import './styles/ChooseQuiz.scss';
import titles from '../data/titles.json';

const ChooseQuiz = (props) => {

    let quizAttributes = {
        pathname: `/quiz/${props.chosenDeck.name}`,
        chosenDeck: props.chosenDeck,
        flashcards: props.flashcards,
        parent: props.parent,
        class_id: props.class_id
    }

    return (
        <div
            className="ChooseQuiz deckDetail"
            style={props.chosenDeck.id > -1 ? {} : { display: 'none' }}
        >
            <div className="deckTitle">
                <h2>{props.chosenDeck.name}</h2>
                <h4>{props.chosenDeck.size} cards</h4>
            </div>
            <div className="quizzes">
                <h5>{titles[props.language].quizzes}</h5>
                {props.chosenDeck.size > 0 ?
                    <>
                        <Link to={{ ...quizAttributes, quizType: "conjugate" }}>
                            <button>{titles[props.language].conjugate}</button>
                        </Link>
                        <Link to={{ ...quizAttributes, quizType: "identify_tense" }}>
                            <button>{titles[props.language].identify_tense}</button>
                        </Link>
                        <Link to={{ ...quizAttributes, quizType: "mixture" }}>
                            <button>{titles[props.language].mixture}</button>
                        </Link>
                    </>
                    :
                    <div className="instruction">
                        Add cards to this deck to get quizzed.
                    </div>
                }
            </div>
        </div>
    );
}

export default ChooseQuiz;